import React from 'react'

/**
 * Get window size.
 * @param inRems Size will be in rems units instead of pixels.
 * @returns Window size.
 */
const useWindowsSize = (inRems?: boolean) => {

    const getSize = () => {

        const size = {
            width: window.innerWidth,
            height: window.innerHeight
        }

        if(inRems) {
            const bodyFontSize = parseFloat(getComputedStyle(document.body)['font-size' as any])

            size.width /= bodyFontSize
            size.height /= bodyFontSize
        }

        return size
    }

    const [size, setSize] = React.useState(getSize())

    React.useEffect(() => {
        const handleResize = () => {
            setSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return size

}

export default useWindowsSize