import React from 'react'
import Styled, { keyframes } from 'styled-components'

import { Image, Size } from '../../Style/Constants/Mixin'

interface Static {
    Layer: string
}

interface Props extends React.ComponentPropsWithoutRef<'div'> {

}

const Parallax = keyframes`
    from {
        background-position: center 2160px;
    }
    to {
        background-position: center 0px;
    }
`

const Root = Styled.div`
    ${Size()}
    left: 0;
    position: absolute;
    top: 0;
    pointer-events: none;
`

interface LayerProps {
    value: number
}

const Layer = Styled.div<LayerProps>`
    ${props => Image(`Home/Stars${props.value}.png`, '1920px auto')}
    ${Size()}
    animation: ${Parallax} ${props => (props.value + 1) * 50}s linear infinite;
    background-repeat: repeat;
    left: 0;
    position: absolute;
    top: 0;

    &:after {
        ${props => Size((props.value + 1) + 'px')}
        content: "";
        position: absolute;
        top: 0;
        left: 0;
    }
`

const Background: React.FC<Props> & Static = ({ ...props }) => {

    const layers = React.useMemo(() => (
        new Array(3).fill(null).map((_, i) => (
            <Layer value={i} key={i} />
        ))
    ), [])

    return (
        <Root {...props}>
            {layers}
        </Root>
    )

}

Background.Layer = Layer

export default Background