import React from 'react'
import { Zoom } from 'react-awesome-reveal'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    duration?: number
    order?: number
}

export type Type = React.FC<Props> & Static

const isTouch = 'ontouchstart' in window

const Reveal: Type = ({ order, ...props }) => {

    const randDelay = Math.random() * 500
    return isTouch ? <div children={props.children} /> : <Zoom {...props} cascade={true} triggerOnce={true} delay={order ? (order * props.duration! / 4) : randDelay} />

}

Reveal.defaultProps = {
    duration: 500,
    order: 0
}

export default Reveal