import React from 'react'
import Styled from 'styled-components'

import Color from '../../Style/Constants/Color'
import Duration from '../../Style/Constants/Duration'
import { Gap, Size } from '../../Style/Constants/Mixin'

export interface Static {

}

export interface Props extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'> {
    count: number
    active: number
    onClick?: (index: number) => void
}

export type Type = React.FC<Props> & Static

interface ItemProps {
    isActive: boolean
}

const Root = Styled.nav`
    text-align: center;
    width: 100%;
`

const Item = Styled.button<ItemProps>`
    ${Gap('0.5rem')}
    ${Size('0.5rem')}
    border: 1px solid ${Color.LIGHT};
    border-radius: 100%;
    box-sizing: border-box;
    padding: 0;
    transition: background-color ${Duration.MEDIUM};
    
    ${({ isActive }) => isActive && `
        background-color: ${Color.LIGHT};
    `}
`



const DotNav: Type = ({ count, active, onClick, ...props }) => {

    const items = React.useMemo(() => { // TODO: Render n times?
        const result = []

        for (let i = 0; i < count; i++) {
            result.push(
                <Item onClick={onClick as any} isActive={i === active} key={i} />
            )
        }

        return result
    }, [count, active, onClick])

    return (
        <Root {...props}>
            {items}
        </Root>
    )

}

export default DotNav