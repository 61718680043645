import React from 'react'
import Styled  from 'styled-components'
import { useSwipeable } from 'react-swipeable'
import Dimension from '../../Style/Constants/Dimension'
import Duration from '../../Style/Constants/Duration'
import { Layout, Small } from '../../Style/Constants/Media'
import { OpacityHover, Size, Image } from '../../Style/Constants/Mixin'
import ZIndex from '../../Style/Constants/ZIndex'
import useKeyboard from '../../Utils/Hooks/UseKeyboard'

export interface Static {
    Items: string
    Item: string
    ArrowLeft: string
    ArrowRight: string
}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    children: React.ReactNode[]
    isInfinite?: boolean
    withArrows?: boolean
    withKeyboard?: boolean
    viewSize: number
    onMove?: (position: number, direction: number) => void
    position?: number
    defaultPosition?: number
    renderNav?: (current: number, max: number, size: number) => React.ReactNode
    full?: boolean
}

export type Type = React.FC<Props> & Static

interface ArrowProps {
    isVisible: boolean
}

interface ItemProps {
    isVisible: boolean
    viewSize: number
    position: number
    leftIndex?: number
    rightIndex?: number
    isLeft: boolean
    isRight: boolean
}


interface ItemsProps {
    viewSize: number
}

const Items = Styled.section<ItemsProps>`
    position: relative;
    margin: 0 auto;
    width: calc(100% - 1rem);
    white-space: nowrap;
    max-width: ${({ viewSize }) => `calc(${viewSize} * ${Dimension.LAYOUT_WIDTH} / ${viewSize})`};
`

const Item = Styled.section<ItemProps>`
    display: inline-block;
    opacity: ${({ isVisible }) => isVisible ? 1 : 0.5};
    overflow: hidden;
    position: relative;
    transform: ${({ position }) => `translateX(${position * 100}%)`};
    transition: transform ${Duration.MEDIUM} ease-in-out, opacity ${Duration.FAST} ease-in-out;
    top: 0;
    vertical-align: top;
    width: calc(100% / ${({ viewSize }) => viewSize});
    white-space: normal;
    
    ${Layout`
        transform: ${({ position, isLeft, isRight }: any) => `translateX(${position * 100}%) translateX(${isLeft ? '0' : (isRight ? '100%' : '50%')})`};
        width: calc(100% / ${({ viewSize }: any) => viewSize + 1});
    `}
    
    ${Small`
        transform: ${({ position }: any) => `translateX(${position * 100}%)`};
        width: 100%;
    `}
    
    & > * {
        margin: 0 auto;
        width: calc(100% - 1rem);
    }
    
    ${({ isVisible }) => !isVisible && `
        & > * {
            pointer-events: none;
        }
    `}
`

const Root = Styled.div`
    position: relative;
    width: 100%;
    max-width: 100vw;
`

const ArrowLeft = Styled.button<ArrowProps>`
    ${Image('Controls/LeftArrow.svg', '60%')}
    ${OpacityHover()}
    ${Size('3rem')}
    display: ${({ isVisible }) => isVisible ? 'block' : 'none'};
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: ${ZIndex.SLIDER_ARROW};
`

const ArrowRight = Styled(ArrowLeft)`
    ${Image('Controls/RightArrow.svg', '60%')}
    left: auto;
    right: 0;
`

const Slider: Type = ({ full, children, isInfinite, withArrows, viewSize, onMove, position, withKeyboard, renderNav, defaultPosition, ...props }) => {

    const [index, setIndex] = React.useState([position || defaultPosition, 0])
    const start = 0
    const end = children.length - viewSize
    const safeIndex = React.useMemo(() => Math.min(end, Math.max(start, index[0]!)), [index])
    const isLeft = safeIndex === 0
    const isRight = children.length - viewSize === safeIndex

    React.useEffect(() => move(0), [viewSize])

    React.useEffect(() => {
        const change = position! - index[0]!
        setIndex([position || defaultPosition, Math.abs(change) === children.length - 1 ? Math.sign(-change) : change]) // TODO: Refactor, Gallery3D.
    }, [position])

    const move = (change: number) => {
        let newIndex

        if (isInfinite) {
            newIndex = Math.min(end, Math.max(start, (safeIndex + change) < 0 ? end : (index[0]! + change) > (children.length - 1) ? start : safeIndex + change))
        } else {
            newIndex = Math.min(end, Math.max(start, safeIndex + change))
        }

        if (!position) {
            setIndex([newIndex, change])
        }

        if (onMove) {
            onMove(newIndex, change)
        }
    }


    const swipe = useSwipeable({
        onSwipedLeft: () => move(viewSize),
        onSwipedRight: () => move(-viewSize),
        delta: 50
    })

    useKeyboard({
        ArrowRight: () => move(viewSize),
        ArrowLeft: () => move(-viewSize)
    }, !!withKeyboard)

    const handleItemClick = (position: number) => {
        if (position <= -1) {
            move(-viewSize)
        } else if (position >= viewSize) {
            move(viewSize)
        }
    }

    const slides = React.useMemo(() => (
        children.map((slide, i) => {
            const position = ((i - safeIndex) + children.length) % children.length
            const realPosition = position > children.length / 2 ? -(children.length - position) : position

            return (
                <Item
                    onClick={() => handleItemClick(i - safeIndex)}
                    position={-safeIndex}
                    viewSize={viewSize}
                    isVisible={(i - safeIndex) >= 0 && (i - safeIndex) < viewSize}
                    key={i}
                    isLeft={full || isLeft!}
                    isRight={isRight}
                    data-position={realPosition}
                    data-change={index[1] || 0}
                    style={{ zIndex: children.length - Math.abs(realPosition) }}>
                    {slide as any}
                </Item>
            )
        })
    ), [children, safeIndex, viewSize])

    return (
        <Root {...props} {...swipe}>
            {renderNav && renderNav(safeIndex, children.length, viewSize)}
            {withArrows && (
                <ArrowLeft
                    onClick={() => move(-viewSize)}
                    isVisible={(safeIndex > start || !!isInfinite)} />
            )}
            <Items viewSize={viewSize}>
                {slides}
            </Items>
            {withArrows && (
                <ArrowRight
                    onClick={() => move(viewSize)}
                    isVisible={withArrows && (safeIndex < end || !!isInfinite)} />
            )}
        </Root>
    )
}

Slider.Items = Items
Slider.Item = Item
Slider.ArrowLeft = ArrowLeft
Slider.ArrowRight = ArrowRight

Slider.defaultProps = {
    viewSize: 1,
    defaultPosition: 0
}

export default Slider