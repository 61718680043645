import React from 'react'
import Styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import Contact from './Contact'
import Topics from '../../Topic/Constants/List'
import Config from '../../Utils/Constants/Config'
import Url from '../../Routing/Constants/Url'
import { Inner } from '../../Style/Components/Components'
import Color from '../../Style/Constants/Color'
import Duration from '../../Style/Constants/Duration'
import { Medium, Large } from '../../Style/Constants/Media'
import { ThreeDots } from '../../Style/Constants/Mixin'
import Link from '../../Routing/Components/Link'

interface Props {

}

const Root = Styled.footer`
    background-color: ${Color.DARKEST};
    box-sizing: border-box;
    margin-top: 4rem;
    padding: 4rem 0;
    position: relative;
    width: 100%;
    
    ${Medium`
        margin-top: 2rem;
        padding: 3rem 0;
    `}
`

const FooterContact = Styled(Contact)`
    display: inline-block;
    
    ${Medium`
        margin-bottom: 3rem;
    `}
`

const Links = Styled.nav`
    width: 30rem;
    
     & > div {
        display: inline-block;
        width: 50%;
    }
    
    ${Large`
        width: 23rem;
        max-width: 100%;
    `}
    
    ${Medium`
        div {
            box-sizing: border-box;
            padding: 0 0.5rem;
        
            &:nth-of-type(2n + 1) {
                text-align: right;
            }
        }
    `}
`

const NavLink = Styled(Link)`
    ${ThreeDots()}
    border-bottom: 1px solid transparent;
    margin-top: 0.5rem;
    transform: border-color ${Duration.MEDIUM};
    max-width: 100%;
    
    &:hover {
        border-color: ${Color.LIGHTEST};
    }
`

const InnerFooter = Styled(Inner)`
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 60rem;
    
    ${Medium`
        flex-direction: column;
    `}
`

const Footer: React.FC<Props> = ({ ...props }) => {

    const location = useLocation()

    if (!location.pathname || location.pathname === '/') {
        return null
    }

    const renderLinks = () => (
        Topics.map((topic, i) => (
            <div key={i}>
                <NavLink pathname={topic.id}>
                    {topic.title}
                </NavLink>
            </div>
        ))
    )

    return (
        <Root {...props}>
            <InnerFooter>
                <FooterContact />
                <Links>
                    <div>
                        <NavLink pathname={Url.HOME}>
                            {Config.name}
                        </NavLink>
                    </div>
                    {renderLinks()}
                </Links>
            </InnerFooter>
        </Root>
    )

}

export default Footer