import React from 'react'
import Styled from 'styled-components'

import { Size } from '../../Style/Constants/Mixin'

export interface Static {
    Height: string
    Inner: string
}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {

}

export type Type = React.FC<Props> & Static

const Root = Styled.div`

`

const Height = Styled.div`
    ${Size('100%', 0)}
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
`

const Inner = Styled.div`
    ${Size()}
    left: 0;
    position: absolute;
    top: 0;
`

const Ratio: Type = ({ children, ...props }) => {

    return (
        <Root {...props}>
            <Height>
                <Inner>
                    {children}
                </Inner>
            </Height>
        </Root>
    )

}


Ratio.Height = Height
Ratio.Inner = Inner

export default Ratio