enum ContentType {

    GRID,
    TIMELINE,
    PYRAMID,
    GALLERY_3D,
    CASCADE,
    GALLERY_SLIDER,
    SET

}

export default ContentType