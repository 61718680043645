module DateTime {

    type Formatter = (value: number) => string

    export const formatYearsDistance = (time: number) => {
        const distance = (new Date(time).getFullYear() - 1970)

        if (distance === 1) {
            return distance + ' rok'
        } else if (distance < 5) {
            return distance + ' roky'
        } else {
            return distance + ' let'
        }
    }

    export const formatMonthYear = (value: number) => {
        const date = new Date(value)
        return date.getMonth() + '/' + date.getFullYear()
    }

    export const getInterval = (start: number, end?: number, formatter: Formatter = formatMonthYear) => {
        const from = formatter(start)
        const to = (end ? formatter(end) : null)
        return from + (to && to !== from ? ' - ' + to : '')
    }

    export const getTime = (year: number, month: number = 0, day: number = 1) => {
        const date = new Date()
        date.setUTCFullYear(year)
        date.setUTCMonth(month)
        date.setUTCDate(day)
        return date.getTime()
    }

    export const getNextYear = () => {
        const nextYear = new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0, 0)
        return nextYear.getTime()
    }

    export const addYears = (time: number, years: number) => {
        const date = new Date(time)
        date.setFullYear(date.getFullYear() + years)
        return date.getTime()
    }

}

export default DateTime