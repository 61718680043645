import React from 'react'
import Styled from 'styled-components'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    children: React.ReactNode[]
    fill?: number
}

export type Type = React.FC<Props> & Static

interface RootProps {
    isReverse?: boolean
}

const Root = Styled.section<RootProps>`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    
    ${({ isReverse }) => isReverse && `
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
    `}
    
    & > * {
        flex-grow: 1
    }
`

const EmptyItem = Styled.div`
    height: 1px;
    overflow: hidden;
    visibility: hidden;
`

const Grid: Type = ({ children, fill, ...props }) => {

    const renderEmptyItems = () => {
        if (!fill) {
            return null
        }

        const emptyItems = []

        for (let i = 0; i < fill; i++) {
            emptyItems.push(
                <EmptyItem key={i}>
                    {children[0]}
                </EmptyItem>
            )
        }

        return emptyItems
    }

    return (
        <Root {...props} isReverse={!fill}>
            {fill ? children : children.slice(0).reverse()}
            {renderEmptyItems()}
        </Root>
    )

}

Grid.defaultProps = {
    fill: 5
}

export default Grid