const topics = [
    {
        id: 'astronomie',
        title: 'Astronomie'
    },
    {
        id: 'tv',
        title: 'Filmy'
    },
    {
        id: 'filosofie',
        title: 'Filosofie'
    },
    {
        id: 'fyzika',
        title: 'Fyzika'
    },
    {
        id: 'hudba',
        title: 'Hudba'
    },
    {
        id: 'literatura',
        title: 'Literatura'
    },
    {
        id: 'programovani',
        title: 'Programování'
    }
]

export default topics