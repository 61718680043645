import { css } from 'styled-components'

import Duration from './Duration'
import Dimension from './Dimension'
import { Layout } from './Media'

export const Image = (name?: string | null, size: string = '100% 100%', position: string = 'center center') => css`
    ${name && `background-image: url(/Images/${name});`}
    background-position: ${position};
    background-repeat: no-repeat;
    background-size: ${size};
`

export const FlexCenter = () => css`
    align-items: center;
    display: flex;
    justify-content: center;
`

export const Size = (width: string | 0 = '100%', height: string | 0 = width, oneLine: boolean = false) => css`
    height: ${height};
    line-height: ${oneLine ? height : undefined};
    width: ${width};
`

export const OpacityHover = (opacity: number = 0.5, duration: string = Duration.FAST) => css`
    opacity: ${opacity};
    transition: opacity ${duration};
    
    &:hover {
        opacity: 1;
    }
`

export const ThreeDots = () => css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

`

export const FontFace = (name: string, path: string, fontWeight?: string) => css`
    @font-face {
        font-family: ${name};
        font-display: swap;
        src: url(/Fonts/${path});
        
        ${fontWeight && `
            font-weight: ${fontWeight};
        `}
    }
`

export const LayoutIndent = (direction: string = 'left') => css`
    margin-${direction}: calc(0.5rem + ((100vw - ${Dimension.LAYOUT_WIDTH}) / 2));
    
    ${Layout`
        margin-${direction}: 0;
    `}
`

export const Gap = (margin: string) => css`
    margin-right: ${margin};
    
    &:last-child {
        margin-right: 0;
    }
`
