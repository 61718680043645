import React from 'react'
import Styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useAsync } from '@react-hook/async'

import { Query } from '../../Routing/Components/Link'
import Slider from './Slider'
import { getItems } from '../../Topic/Utils/Api'
import Ratio from '../../Layout/Components/Ratio'
import Queries from '../../Routing/Utils/Queries'
import Urls from '../../Routing/Utils/Urls'
import Color from '../../Style/Constants/Color'
import { Medium } from '../../Style/Constants/Media'
import { OpacityHover, Size, Image } from '../../Style/Constants/Mixin'
import ZIndex from '../../Style/Constants/ZIndex'
import useKeyboard from '../../Utils/Hooks/UseKeyboard'
import Link from '../../Routing/Components/Link'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    queryName: Query
}

export type Type = React.FC<Props> & Static

const VideoRoot = Styled.div`
    bottom: 1rem;
    position: fixed;
    right: 1rem;
    width: 30rem;
    z-index: ${ZIndex.FIXED_VIDEO};
    max-width: 100%;
    
    ${Medium`
        bottom: 0;
        right: 0;
        width: 20rem;
    `}
`

const VideoControls = Styled.div`
    position: absolute;
    pointer-events: none;
    text-align: right;
    top: -2rem;
    width: 100%;
`

const VideoControl = Styled(Link)`
    ${OpacityHover()}
    ${Size('2rem')}
    background-size: 60% !important;
    display: inline-block;
    pointer-events: all;
    
    &:first-of-type {
        ${Image('Controls/LeftArrow.svg')}
    }
    
    &:nth-of-type(2) {
        ${Image('Controls/RightArrow.svg')}
    }
    
    &:last-of-type {
        ${Image('Controls/Close.svg')}
    }
`

const ImageRoot = Styled.section`
    ${Size()}
    background-color: ${Color.DARKEST};
    left: 0;
    position: fixed;
    top: 0;
    z-index: ${ZIndex.FULL_SCREEN_GALLERY};
`

const ImageGalleryCarousel = Styled(Slider)`
    height: 100%;

    ${Slider.Items} {
        ${Size()}
        max-width: none;
    }
    
    ${Slider.Item} {
        ${Size()}
        opacity: 1;
    }
`

const ImageWrapper = Styled.div`
    ${Size()}
    align-items: center;
    display: flex;
    justify-content: center;
`

const ImageItem = Styled.img`
    max-height: 100%;
    max-width: 100%;
`

const ImageCloseButton = Styled(Link)`
    ${Size('3rem')}
    ${Image('Controls/Close.svg', '40%')}
    ${OpacityHover()}
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2000;
`

const QUERY_SEPARATOR = '_'

const FixedSlider: Type = ({ queryName, ...props }) => {

    const isImage = queryName === Query.IMAGE
    const location = useLocation()
    const [topicId, sectionId] = (Queries.get(location.search, queryName) || '').split('_')
    const [{ value: items }, call] = useAsync(() => getItems(topicId, sectionId))

    React.useEffect(() => {
        call()
    }, [topicId, sectionId])

    useKeyboard({
        Escape: () => Urls.replace({ query: { [queryName]: null } })
    }, isImage)


    const [section, subsection, position] = (Queries.get(location.search, queryName) || '').split(QUERY_SEPARATOR)
    const index = parseInt(position) - 1
    if (!items || !position) return null

    const getSafeIndex = (offset: number) => (
        (index + items.length + offset) % items.length
    )

    const getQuery = (increment: number) => (
        [section, subsection, getSafeIndex(increment) + 1].join(QUERY_SEPARATOR)
    )

    if (isImage) {
        const handleMove = (position: number) => {
            Urls.replace({ query: { [Query.IMAGE]: getQuery(position - index) } })
        }

        const renderItems = () => (
            items.map((item: any, i: number) => (
                <ImageWrapper key={i}>
                    <ImageItem src={item.path} />
                </ImageWrapper>
            ))
        )

        return (
            <ImageRoot {...props}>
                <ImageGalleryCarousel
                    position={index}
                    onMove={handleMove}
                    withArrows={true}
                    withKeyboard={true}
                    viewSize={1}
                    full={true}>
                    {renderItems()}
                </ImageGalleryCarousel>
                <ImageCloseButton query={{ [Query.IMAGE]: null }} />
            </ImageRoot>
        )
    } else {
        const videoId = items[getSafeIndex(0)]?.video || items[getSafeIndex(0)]?.path
        if (!videoId) return null

        return (
            <VideoRoot {...props}>
                <VideoControls>
                    <VideoControl query={{ [Query.VIDEO]: getQuery((-1)) }} replace={true} />
                    <VideoControl query={{ [Query.VIDEO]: getQuery(1) }} replace={true} />
                    <VideoControl query={{ [Query.VIDEO]: null }} replace={true} />
                </VideoControls>
                <Ratio>
                    <iframe
                        width='100%'
                        height='100%'
                        src={'https://www.youtube.com/embed/' + videoId + '?autoplay=1'}
                        frameBorder='0'
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </Ratio>
            </VideoRoot>
        )
    }
}

export default FixedSlider