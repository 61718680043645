enum ZIndex {

    HEADER = 10,
    TIMELINE_TICK,
    TIMELINE_EVENT,
    VIDEO_PLAY,
    FIXED_VIDEO,
    SLIDER_ARROW,
    REFLECTION,
    HOVER_AREA,
    HIERARCHICAL_NAV_ARROW,
    ANCHOR_NAV,
    FULL_SCREEN_GALLERY,

}

export default ZIndex