import React from 'react'
import useEvent from '@react-hook/event'

import Dimension from '../../Style/Constants/Dimension'

module Converter {

    const getBodyFontSize = () => (
        16
        //parseFloat(getComputedStyle(document.body)['font-size'])
    )

    export const pxToRem = (px: number) => (
        px / getBodyFontSize()
    )

    export const remToPx = (rem: number) => (
        rem * getBodyFontSize()
    )

}

const is = (px: number, dimension: string) => Converter.pxToRem(px) < parseInt(dimension)

const getResult = () => {
    const width = window.innerWidth

    return ({
        isSmall: is(width, Dimension.SMALL_WIDTH),
        isLarge: is(width, Dimension.LARGE_WIDTH)
    })
}

const useWidthBreakpoint = () => {
    const [result, setResult] = React.useState(getResult())
    useEvent( window, 'resize', () => setResult(getResult))
    return result
}

export default useWidthBreakpoint