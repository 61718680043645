const Config = {
    //gaId: 'UA-152631020-1'
    gaId: 'UA-152631020-2',
    born: new Date(1997, 0, 1, 0, 0, 0, 0).getTime(),
    name: 'Michal Struna',
    sites: [
        ['Fb', 'https://www.facebook.com/michal.struna.7'],
        ['Github', 'https://github.com/michalstruna'],
        ['LinkedIn', 'https://www.linkedin.com/in/michal-struna-4382611a4/'],
        ['Csfd', 'https://www.csfd.cz/uzivatel/508903-oxtimus/'],
        ['Mal', 'https://myanimelist.net/profile/_michal']
    ],
    email: 'michal.l.struna@gmail.com'
}

export default Config