import React from 'react'
import IsImage from 'is-image'
import { useLocation, useParams } from 'react-router-dom'

import { Query } from '../../Routing/Components/Link'
import { Props as SliderProps } from './Slider'
import Queries from '../../Routing/Utils/Queries'

export interface ProviderStatic {

}

export interface ProviderProps extends React.ComponentPropsWithoutRef<'div'>, Omit<SliderProps, 'children'> {
    children: (props: any) => React.ReactElement
    items: any[]
}

export type ProviderType = React.FC<ProviderProps> & ProviderStatic

const QUERY_SEPARATOR = '_'

const FixedSliderProvider: ProviderType = ({ id, children, items, ...props }) => {

    const isImage = items[0] && IsImage(items[0].video || items[0].path)
    const queryName = isImage ? Query.IMAGE : Query.VIDEO
    const location = useLocation()
    const params = useParams<any>()
    const query = Queries.get(location.search, queryName) || ''
    const [section, subsection, position] = query.split(QUERY_SEPARATOR)

    const currentSection = params.topic

    const linkedItems = React.useMemo(() => (
        items.map((item, i) => {
            const isActive = currentSection === section && id === subsection && (i + 1) === parseInt(position)

            return {
                ...item,
                link: { query: { [queryName]: isActive ? null : currentSection + QUERY_SEPARATOR + id + QUERY_SEPARATOR + (i + 1) } },
                isActive
            }
        })
    ), [items, id, query])

    return children({ ...props, items: linkedItems, id })

}

export default FixedSliderProvider