import React from 'react'

const useKeyboard = (keyMap: Record<string, (event: KeyboardEvent) => void>, isActive: boolean = true) => {
    React.useEffect(() => {
        if (isActive) {
            const handler = (event: KeyboardEvent) => {
                for (const key in keyMap) {
                    if (key === event.key) {
                        keyMap[key](event)
                    }
                }
            }

            window.addEventListener('keydown', handler)
            return () => window.removeEventListener('keydown', handler)
        }
    }, [keyMap, isActive])
}

export default useKeyboard