import React from 'react'
import Styled from 'styled-components'

import { Target } from '../../Routing/types'
import Duration from '../../Style/Constants/Duration'
import { Size, Image } from '../../Style/Constants/Mixin'
import Link from '../../Routing/Components/Link'

export interface Static {
    Layer: string
}

export interface Props extends Omit<React.ComponentPropsWithoutRef<any>, 'name'>, Target {
    name: string | string[]
    active?: boolean | number
}

export type Type = React.FC<Props> & Static

interface ImageProps {
    isActive: boolean
    path: string
}

const Layer = Styled.div<ImageProps>`
    ${({ path }) => Image(path)}
    ${Size()}
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity ${Duration.MEDIUM};
    
    ${({ isActive }) => isActive && `
        opacity: 1;
    `}
`

const Root = Styled(Link)`
    ${Size('2rem')}
    position: relative;
    
    &:hover {
        ${Layer} {
            opacity: 1;
        }
    }
`

const MultiImage: Type = ({ name, active, ...props }) => {

    const paths = typeof name === 'string' ? [name, name.replace(/\.([a-z]{3})/, 'Active.$1')] : name

    const renderImages = () => (
        paths.map((path, i) => (
            <Layer isActive={active === undefined ? i === 0 : active === i} path={path} key={i} />
        ))
    )

    return (
        <Root {...props}>
            {renderImages()}
        </Root>
    )

}

MultiImage.Layer = Layer

export default MultiImage