const Dimension = {
    SMALL_WIDTH: '36rem',
    MEDIUM_WIDTH: '45rem',
    LARGE_WIDTH: '64rem',
    LAYOUT_WIDTH: '95rem',

    NAV_HEIGHT: '2.5rem',

    MAX_IMAGE_GALLERY_4_COLUMNS: '95rem',
    MAX_IMAGE_GALLERY_3_COLUMNS: '80rem',
    MAX_IMAGE_GALLERY_2_COLUMNS: '70rem',
    MAX_IMAGE_GALLERY_1_COLUMN: '50rem',

    MAX_VIDEO_GALLERY_2_COLUMNS: '95rem',
    MAX_VIDEO_GALLERY_1_COLUMN: '70rem'

}

export default Dimension