import React from 'react'
import Styled from 'styled-components'

import HierarchicalNav from '../../Layout/Components/HierarchicalNav'
import Anchor from '../../Routing/Components/Anchor'
import AnchorNav from '../../Routing/Components/AnchorNav'
import Url from '../../Routing/Constants/Url'
import Hash from '../../Routing/Constants/Hash'
import { LinkData } from '../../Routing/types'
import { Title, Inner, Parallax } from '../../Style/Components/Components'
import Color from '../../Style/Constants/Color'
import { Medium } from '../../Style/Constants/Media'
import { Size } from '../../Style/Constants/Mixin'

import Topics from '../Constants/List'
import { Topic } from '../types'

interface Props extends React.ComponentPropsWithoutRef<'div'> {
    topic?: Topic
    topicId: string
}

interface ImageContainerProps {
    path: string
}

const Root = Styled.section`
    ${Size('100%', '25rem')}
    position: relative;
    
    ${Title} {
        text-align: left;
    }
    
    ${Medium`
        height: 15rem;
    `}
`

const InnerTitleImage = Styled.div`
    height: 100%;
`

const Bottom = Styled.div`
    bottom: 0;
    position: absolute;
    width: 100%;
`

const ImageContainer = Styled(Parallax)<ImageContainerProps & any>`
    ${Size()}
    position: absolute !important;
    overflow: hidden !important;

    img {
        margin-top: -5%;
    }
`

const DarkLayer = Styled.section`
    ${Size()}
    background-color: ${Color.DARKEST};
    opacity: 0.7;
    position: absolute;
`

const TitleImage: React.FC<Props> = ({ topic, topicId, ...props }) => {

    return (
        <Root {...props}>
            <Anchor name={Hash.HOME} key={topicId} />
            <ImageContainer bgImage={`/Images/Topics/${topicId}/Title.jpg`} strength={500} />
            <DarkLayer />
            <InnerTitleImage>
                <Inner>
                    <HierarchicalNav
                        links={[
                            [{ text: 'Michal Struna', pathname: Url.HOME }],
                            Topics.map(topic => ({ pathname: '/' + topic.id, text: topic.title }))]}
                        current={[null as any, '/' + topicId]} />
                </Inner>
                {topic && <Bottom>
                    <Inner>
                        <Title>
                            {topic.title}
                        </Title>
                    </Inner>
                    <AnchorNav
                        links={[{ text: 'Domů', hash: Hash.HOME }, ...topic.items!.map(({ id, title }: any) => ({
                            text: title,
                            hash: id
                        })).filter((link: LinkData) => link.text)]} />
                </Bottom>}
            </InnerTitleImage>
        </Root>
    )

}

export default TitleImage