import React from 'react'
import Styled from 'styled-components'
import { Parallax } from '../../Style/Components/Components'
import Color from '../../Style/Constants/Color'

import { Large, Medium } from '../../Style/Constants/Media'
import { Image, Size } from '../../Style/Constants/Mixin'
import { ContentData } from '../types'
import DataItem from './DataItem'

// TODO: To global.
interface DataItemSuperProps {
    isSmall?: boolean
    isLarge?: boolean
    ratio?: number
}

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'>, DataItemSuperProps {
    image: string
    items: ContentData[]
}

export type Type = React.FC<Props> & Static

interface ItemProps {
    vPosition: number
    hPosition: number
    withDescription: boolean
    isSmall?: boolean
    isLarge?: boolean
}

const Root = Styled.div`
    ${Size('100%', '50rem')}
    margin: 0 auto;
    position: relative;
    
    ${Large`
        height: 45rem;
    `}
    
    ${Medium`
        height: auto;
    `}
`

const Lines = Styled.div`
    ${Image('Layout/Set/Lines.svg', '100% 100%')}
    ${Size('calc(60% - 3rem)', '80%')}
    left: calc(20% + 1.5rem);
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    top: 10%;
    
    ${Large`
        left: calc(27.5% + 1rem);
        opacity: 1;
        width: calc(45% - 2rem);
    `}
    
    ${Medium`
        display: none;
    `}
`

const Main = Styled.div`
    ${Image(null, '100% auto', 'center center')}
    ${Size('29.5%', '100%')}
    background-color: ${Color.BACKGROUND};
    position: relative;
    margin-left: 35%;
    
    ${Large`
        margin-left: 37.5%;
        width: 25%;
    `}
    
    ${Medium`
        background-size: auto 100%;
        margin-left: 0;
        width: 100%;
    `}
`

const MainImage = Styled(Parallax)`
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 auto;
    overflow: visible !important;
    position: relative;
    width: 100%;
    
    ${Medium`
        height: auto;
        top: 0;
        transform: none;
        width: auto;
        max-height: 50vh;
        max-width: 100%;
        height: 20rem;

        img {
            transform: none !important;
            position: relative !important;
            max-width: 100% !important;
            height: auto !important;
            left: 0 !important;
            max-height: 100% !important;
            width: auto !important;
            margin: 0 auto;
        }
    `}
`

const Item = Styled(DataItem)<ItemProps>`
    ${Size('20%', 'auto')}
    overflow: hidden;
    position: absolute;
    ${({ hPosition }) => hPosition > 0 ? 'right: 0;' : 'left: 0;'};
    ${({ vPosition }) => vPosition > 0 ? 'bottom: 0' : (vPosition < 0 ? 'top: 0' : 'top: 50%; transform: translateY(-50%)')};
    text-align: ${({ hPosition }) => hPosition > 0 ? 'left' : 'right'}
    
    ${DataItem.Title} {
        white-space: normal;
    }
    
    ${({ isSmall }) => isSmall && `;
        max-width: 15rem;
    `}
    
    ${Large`
        width: 27.5%;
    `}
    
    ${Medium`
        display: inline-block;
        margin-top: 1rem;
        position: static;
        text-align: left;
        transform: none;
        width: 100%;
        max-width: none;
        
         ${({ withDescription }: any) => !withDescription && `
            vertical-align: top;
            width: 50%;
        `}
    `}
`

const Set: Type = ({ image, items, isSmall, isLarge, ratio, ...props }) => {

    const values = React.useMemo(() => {
        const hPositions = [-1, 1, -1, 1, -1, 1]
        const vPositions = [-1, -1, 0, 0, 1, 1]

        return items.map((item, i) => (
            <Item
                {...item}
                key={i}
                hPosition={hPositions[i]}
                vPosition={vPositions[i]}
                isSmall={isSmall!}
                isLarge={isLarge!}
                ratio={ratio}
                withDescription={!!item.description} />
        ))
    }, [items, isLarge, isSmall, ratio])

    return (
        <Root {...props}>
            <Lines />
            <Main>
                <MainImage bgImage={image} />
            </Main>
            {values}
        </Root>
    )

}

export default Set