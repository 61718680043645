import { css } from 'styled-components'

import Dimension from './Dimension'

export const Small = (...args: any[]) => css`
    @media (max-width: ${Dimension.SMALL_WIDTH}) {
        ${(css as any)(...args)}
    }
`

export const Medium = (...args: any[]) => css`
    @media (max-width: ${Dimension.MEDIUM_WIDTH}) {
        ${(css as any)(...args)}
    }
`

export const Large = (...args: any[]) => css`
    @media (max-width: ${Dimension.LARGE_WIDTH}) {
        ${(css as any)(...args)}
    }
`

export const Layout = (...args: any[]) => css`
    @media (max-width: ${Dimension.LAYOUT_WIDTH}) {
        ${(css as any)(...args)}
    }
`

export const WithHover = (...args: any[]) => css`
    @media (hover: hover) {
        ${(css as any)(...args)}
    }
`

export const WithoutHover = (...args: any[]) => css`
    @media (hover: none) {
        ${(css as any)(...args)}
    }
`