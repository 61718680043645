import React from 'react'

import Styled from 'styled-components'
import { ItemSubtitle } from '../../Style/Components/Components'
import Color from '../../Style/Constants/Color'
import { Gap, OpacityHover, Size, Image } from '../../Style/Constants/Mixin'
import { Attachment } from '../types'
import Link from '../../Routing/Components/Link'

export interface Static {

}

export interface Props extends Omit<React.ComponentPropsWithoutRef<'div'>, 'data'> {
    data: Attachment
}

export type Type = React.FC<Props> & Static

const Root = Styled.section`
    background-color: ${Color.MEDIUM};
    margin-top: 1rem;
    padding: 0.5rem;
`

const Header = Styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
`

const Title = Styled(ItemSubtitle)`
    width: 100%;
`

const Description = Styled.p`
    margin-top: 0.5rem;
`

const Links = Styled.div`
    display: flex;
`

const AttachmentLink = Styled(Link)`
    ${Gap('0.35rem')}
    ${Image(null)}
    ${OpacityHover()}
    ${Size('1.5rem')}
`

const AttachmentBlock: Type = ({ data, ...props }) => {

    const links = React.useMemo(() => {
        if (!data.links) {
            return null
        }

        return (
            <Links>
                {data.links.map((link, i) => (
                    <AttachmentLink
                        pathname={link}
                        key={i}
                        style={{ backgroundImage: `url(/Images/Layout/Attachment/${/\.pdf$/.test(link) ? 'Pdf' : (/github\.com/.test(link) ? 'Github' : 'Web')}.svg)` }} />
                ))}
            </Links>
        )
    }, [data.links])

    return (
        <Root {...props}>
            <Header>
                <Title>
                    {data.title}
                </Title>
                {links}
            </Header>
            <Description>
                {data.description}
            </Description>
        </Root>
    )

}

export default AttachmentBlock