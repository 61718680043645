import Styled from 'styled-components'
import { Parallax as ParallaxRoot, ParallaxProps } from 'react-parallax'

import Dimension from '../Constants/Dimension'
import { Large, Medium, Small } from '../Constants/Media'
import { ThreeDots } from '../Constants/Mixin'
import useElement from '../../System/Hooks/useElement'

export const View = Styled.section`
    overflow: hidden;
    width: 100%;
`

export const Inner = Styled.section`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
    width: 100%;
    max-width: ${Dimension.LAYOUT_WIDTH};
`

export const Title = Styled.h1`
    font-size: 500%;
    font-variant: small-caps;
    font-weight: lighter;
    letter-spacing: 0.2em;
    margin: 2rem 0;
    text-align: center;
    
    ${Large`
        font-size: 380%;
    `}
    
    ${Medium`
        font-size: 300%;
        margin: 1rem 0;
        word-spacing: 100vw;
    `}
    
    ${Small`
        font-size: 230%;
        letter-spacing: 0.1em;
    `}
`

export const SectionTitle = Styled.h2`
    font-size: 200%;
    margin-top: 3rem;
    margin-bottom: 2rem;
    
    ${Large`
        font-size: 170%;
        margin-bottom: 1rem;
        margin-top: 2rem;
    `}
    
    ${Small`
        font-size: 130%;
    `}
`

export const ItemTitle = Styled.h3`
    ${ThreeDots()}
    font-size: 130%;
    margin-bottom: 0.5rem;
    
    ${Small`
        font-size: 100%;
        font-weight: bold;
    `}
`

export const ItemSubtitle = Styled.h4`
    ${ThreeDots()}
    font-size: 110%;
    font-weight: bold;
    
    ${Small`
        font-size: 100%;
    `}
`

export const Parallax: React.FC<Omit<React.ComponentPropsWithoutRef<'div'>, 'onLoad' | 'style'> & ParallaxProps> = ({...props }) => {

    const { app } = useElement()

    return (
        <ParallaxRoot {...props} parent={app} />
    )

}