import React from 'react'
import Styled from 'styled-components'

import Slider from './Slider'
import SectionControls from '../../Topic/Components/SectionControls'
import DataItem from '../../Layout/Components/DataItem'
import { ContentData } from '../../Layout/types'
import Dimension from '../../Style/Constants/Dimension'
import { Small } from '../../Style/Constants/Media'
import useResponsiveData from '../../Style/Hooks/UseResponsiveData'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    isLarge?: boolean
    items: ContentData[]
    ratio?: number
}

export type Type = React.FC<Props> & Static

interface WithFullScreenProps {
    isFullScreen: boolean
}

const Root = Styled(Slider)<WithFullScreenProps>`
    ${Small`
        ${Slider.Item} {
            width: 100% !important;
        }
    `}    
`

const SliderItem = Styled(DataItem)<WithFullScreenProps>`
    ${({ isFullScreen }) => isFullScreen && `
        display: none;
    `}
`

const GallerySlider: Type = ({ items, isLarge, ratio, ...props }) => {

    const viewSize = useResponsiveData(isLarge ? [[Dimension.MAX_VIDEO_GALLERY_1_COLUMN, 1], [Dimension.MAX_VIDEO_GALLERY_2_COLUMNS, 2], 3] : [[Dimension.MAX_IMAGE_GALLERY_1_COLUMN, 1], [Dimension.MAX_IMAGE_GALLERY_2_COLUMNS, 2], [Dimension.MAX_IMAGE_GALLERY_3_COLUMNS, 3], [Dimension.MAX_IMAGE_GALLERY_4_COLUMNS, 4], 5])
    const [loaded, setLoaded] = React.useState(0)

    const dataItems = React.useMemo(() => (
        items.map((item, i) => i <= loaded && (
            <SliderItem isStatic={i > viewSize} {...item as any} ratio={ratio} key={i} order={i} />
        ))), [items, loaded])

    return (
        <Root
            {...props as any}
            withArrows={true}
            viewSize={viewSize}
            onMove={i => i + viewSize > loaded && setLoaded(i + viewSize)}
            renderNav={(current, max, size) => <SectionControls scrollbar={{ current, max, size, isAnimated: true }} />}>
            {dataItems}
        </Root>
    )


}

GallerySlider.defaultProps = {
    ratio: 16 / 9
}

export default GallerySlider