import React from 'react'
import Styled from 'styled-components'

import Color from '../../Style/Constants/Color'
import { Size } from '../../Style/Constants/Mixin'

export interface Static {
    Inner: string
}

export interface Props extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onScroll'> {
    current: number
    max: number
    size?: number
}

export type Type = React.FC<Props> & Static

interface InnerProps {
    left: number
    size: number
}

const Root = Styled.div`
    ${Size('100%', '0.5rem')}
    background-color: ${Color.MEDIUM};
    position: relative;
`

const Inner = Styled.div<InnerProps>`
    background-color: ${Color.LIGHT};
    height: 100%;
    left: ${({ left }) => left * 100}%;
    position: absolute;
    width: ${({ size }) => Math.min(size, 1) * 100}%;
`

const Scrollbar: Type = ({ current, max, size, ...props }) => {

    return (
        <Root {...props}>
            <Inner left={Math.min(1, current / max)} size={size! / max} />
        </Root>
    )

}

Scrollbar.defaultProps = {
    size: 1
}

Scrollbar.Inner = Inner

export default Scrollbar

// TODO: isHorizontal prop?