import React from 'react'
import Styled from 'styled-components'

import DataItem from '../../Layout/Components/DataItem'
import DotNav from '../../Layout/Components/DotNav'
import Ratio from '../../Layout/Components/Ratio'
import { ContentData } from '../../Layout/types'
import Color from '../../Style/Constants/Color'
import Duration from '../../Style/Constants/Duration'
import { Size } from '../../Style/Constants/Mixin'
import Slider from './Slider'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    items: ContentData[]
}

export type Type = React.FC<Props> & Static

const Root = Styled.div`

`

const Item = Styled(DataItem)`
    ${Size('50rem', '100%')}
    opacity: 0;
    transition: opacity ${Duration.SLOW} ease-in-out, transform ${Duration.SLOW} ease-in-out;
    transform: none;
    
    ${DataItem.Main} ${Ratio.Inner} {
        max-height: none !important;
    }
`

const OuterItem = Styled.div`
    ${Size()}
    margin: 0;
    position: absolute;
    transition: width ${Duration.SLOW} ease-in-out;
    width: 25%;
    
    & > div {
        ${Size()}
    }
`

const InnerItem = Styled.div`
    ${Size()}
    background-color: ${Color.DARKEST};
    box-shadow: 0 0 0.5rem ${Color.LIGHT_MEDIUM};
    display: block;
    overflow: hidden;
    position: relative;
`

const Reflection = Styled.div`
    -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.5, transparent), to(rgba(255, 255, 255, 0.3)));
    
    h3, h4, p {
        display: none;
    }
`

const SingleSlider3D = Styled(Slider)`
    ${Size('50rem', '28rem')}
    margin: 0 auto;
    perspective: 2000px;
    
    ${Slider.ArrowLeft} {
        ${Size('calc(50vw - 25%)', '100%')}
        background-image: none;
        top: 0;
        transform: translateX(-100%) translateX(0.5rem);
    }
    
    ${Slider.ArrowRight} {
        transform: translateX(100%) translateX(-0.5rem);
    }
    
    ${Slider.Items} {
        ${Size()}
    }

    ${Slider.Item} {
        ${Size()}
        opacity: 1;
        overflow: visible;
        position: absolute;
        transform: scale(0);
        transition: transform ${Duration.SLOW} ease-in-out, opacity ${Duration.SLOW} ease-in-out;
        
        &[data-position="-1"], &[data-position="-2"], &[data-position="-3"] {
            ${OuterItem} {
                left: 0;
                right: auto;
            }
        }
        
        
        &[data-position="1"], &[data-position="2"], &[data-position="3"], &[data-position="4"], &[data-position="0"][data-change="1"] {
            ${OuterItem} {
                left: auto;
                right: 0;
            }
        }
        
        &[data-position="1"], &[data-position="2"], &[data-position="3"], &[data-position="4"] {            
            ${Item} {
  
            }
        }
        
        &[data-position="0"] {
            transform: none;
            
            ${OuterItem} {
                width: 100%;
            }
            
            ${Item} {
                opacity: 1;
            }
        }
        
        &[data-position="1"] {
            transform: translateX(20%) rotateY(-10deg) scale(0.9);
            
            ${Item} {
                opacity: 0.6;
            }
        }
                
        &[data-position="-1"] {
            transform: translateX(-20%) rotateY(10deg) scale(0.9);
            
            ${Item} {
                opacity: 0.6;
            }
        }
        
        &[data-position="2"] {
            transform: translateX(35%) rotateY(-20deg) scale(0.8);
            
            ${Item} {
                opacity: 0.4;
            }
        }
        
        &[data-position="-2"] {
            transform: translateX(-35%) rotateY(20deg) scale(0.8);
            
            ${Item} {
                opacity: 0.4;
            }
        }
        
         &[data-position="3"] {
            transform: translateX(45%) rotateY(-25deg) scale(0.7);
            
            ${Item} {
                opacity: 0.2;
            }
        }
                
         &[data-position="-3"] {
            transform: translateX(-45%) rotateY(25deg) scale(0.7);
            
            ${Item} {
                opacity: 0.2;
            }
        }
        
    }
`

const Nav = Styled.nav`
    pointer-events: none;
`

interface InfoProps {
    active: number
}

const Info = Styled.div<InfoProps>`
    display: grid;
    position: relative;
    margin: 0 auto;
    margin-bottom: 2rem;
    width: 30rem;
    max-width: 100%;

    & > * {
        grid-column: 1;
        grid-row: 1;
        opacity: 0;
        pointer-events: none;
        transition: opacity ${Duration.MEDIUM};
        
        &:nth-of-type(${({ active }) => active + 1}) {
            opacity: 1;
            pointer-events: all;
        }
    }
`

const InfoItem = Styled(DataItem)`
    ${DataItem.Title} {
        margin-top: 3rem;
    }
`

const Slider3D: Type = ({ items, ...props }) => {

    const [position, setPosition] = React.useState(0)

    const slides = React.useMemo(() => (
        items.map((item: any, i) => (
            <OuterItem key={i}>
                <InnerItem>
                    <Item isStatic={true} {...item} />
                </InnerItem>
            </OuterItem>
        ))
    ), [items])

    const info = React.useMemo(() => (
        items.map((item, i) => (
            <InfoItem key={i} {...item} path={null!} />
        ))
    ), [items])

    return (
        <Root {...props}>
            <>
                <Reflection>
                    <SingleSlider3D
                        withArrows={true}
                        isInfinite={true}
                        onMove={position => setPosition(position)}
                        viewSize={1}
                        id={props.id}>
                        {slides}
                    </SingleSlider3D>
                </Reflection>
                <Info active={position}>
                    {info}
                </Info>
                <Nav>
                    <DotNav active={position} count={items.length} />
                </Nav>
            </>
        </Root>
    )

}

export default Slider3D