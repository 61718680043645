import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router, Switch, Redirect } from 'react-router-dom'

import Url from './Routing/Constants/Url'
import History from './Routing/Redux/History'
import App from './Base/Components/App'

import HomeView from './Topic/Views/HomeView'
import TopicView from './Topic/Views/TopicView'

ReactDOM.render(
    <Router history={History}>
            <App>
                <Switch>
                    <Route exact path={Url.HOME} component={HomeView} />
                    <Route path='/:topic' component={TopicView} />
                    <Redirect to={Url.HOME} />
                </Switch>
            </App>
    </Router>,
    document.getElementById('root')
)
