import React from 'react'
import Styled, { css } from 'styled-components'
import useEvent from '@react-hook/event'

import { WithHover, WithoutHover } from '../../Style/Constants/Media'
import ZIndex from '../../Style/Constants/ZIndex'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'nav'> {
    main: React.ReactNode
}

export type Type = React.FC<Props> & Static

interface MainProps {
    isVisible: boolean
}

const Root = Styled.div`
    position: relative;
    z-index: ${ZIndex.HOVER_AREA};
`

const Area = Styled.div`
    left: 0;
    position: absolute;
    top: 0;
    display: none;
`

const ActiveArea = css`
    display: block;

`

const Main = Styled.div<MainProps>`
    ${WithHover`
        &:hover + ${Area}, & + ${Area}:hover {
            ${ActiveArea}
        }
    `}
    
    ${WithoutHover`
        ${({ isVisible }: any) => isVisible && `
            & + ${Area} {
                ${ActiveArea}
            }
        `}
    `}
`

const HoverArea: Type = ({ main, children, ...props }) => {

    const [isVisible, setVisible] = React.useState(false)

    useEvent(document, 'click', React.useCallback(() => {
        setVisible(false)
    }, []))

    const handleMainClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        setVisible(!isVisible)
    }

    return (
        <Root {...props}>
            <Main onClick={handleMainClick} isVisible={isVisible}>
                {main}
            </Main>
            <Area>
                {children}
            </Area>
        </Root>
    )

}

export default HoverArea