import React from 'react'
import Styled from 'styled-components'

import DataItem from '../../Layout/Components/DataItem'
import { ContentData } from '../../Layout/types'
import Color from '../../Style/Constants/Color'
import { Medium } from '../../Style/Constants/Media'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    items: ContentData[]
}

export type Type = React.FC<Props> & Static

const Root = Styled.div`
    margin: 0 auto;
    width: 40rem;
    max-width: 100%;
`

const Item = Styled(DataItem)`
    margin: 0.5rem auto;
    
    ${DataItem.Container} {
        background-color: ${Color.MEDIUM};
        box-sizing: border-box;
        padding: 0.5rem;
    }
    
    ${DataItem.Attachment} {
        background-color: ${Color.DARK_MEDIUM};
        
        ${Medium`
            background-color: ${Color.MEDIUM};
        `}
    }
    
    ${Medium`
        margin-top: 1rem;
        width: 100% !important;
        
        ${DataItem.Container} {
            background-color: transparent;
            padding: 0;
        }
    `}
`

const Pyramid: Type = ({ items, ...props }) => {

    const levels = React.useMemo(() => (
        items.slice().reverse().map((item, i) => (
            <Item {...item} key={i} style={{ width: (100 - (items.length - i) * 15) + '%' }} order={i} />
        ))
    ), [items])

    return (
        <Root {...props}>
            {levels}
        </Root>
    )

}

export default Pyramid