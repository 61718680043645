import React from 'react'
import Styled from 'styled-components'
import IsImage from 'is-image'

import { ContentData } from '../types'
import Ratio from './Ratio'
import VideoPreview from './VideoPreview'
import Attachment from './Attachment'
import TagGroup from './TagGroup'
import Reveal from './Reveal'
import Link from '../../Routing/Components/Link'
import { LinkData } from '../../Routing/types'
import Urls from '../../Routing/Utils/Urls'
import { ItemTitle } from '../../Style/Components/Components'
import Color from '../../Style/Constants/Color'
import Duration from '../../Style/Constants/Duration'
import { Size, Gap, OpacityHover, Image } from '../../Style/Constants/Mixin'

export interface Static {
    Attachment: string
    Title: string
    Main: string
    Image: string
    Container: string
    Links: string
}

export interface Props extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>, ContentData {
    link?: LinkData
    isActive?: boolean
    ratio?: number
    isStatic?: boolean
    links?: string[]
    order?: number
    imageHeight?: number
}

export type Type = React.FC<Props> & Static

interface ItemImageProps {
    path: string
    ratio: number
    imageHeight?: number
}

interface RatioProps {
    ratio: number
}

const InnerImage = Styled(Link)<ItemImageProps>`
    ${props => Image(null, `auto ${(props.imageHeight ?? 1) * 100}%`)}
    ${Size()}
    background-image: url(${({ path }) => path});
    transition: transform ${Duration.FAST};
    transform-origin: bottom;
    
    ${({ path, ratio }) => !/\.svg/.test(path) && ratio < 1 && `
        background-size: cover;
        
        &:hover {
            transform: scale(1.03);
        }
    `}
`


const Root = Styled.div`
`

const Container = Styled.div`
    ${Size()}
    box-sizing: border-box;
`

const Title = Styled(ItemTitle)`    

`

const Description = Styled.p`
    line-height: 1.5rem;
    white-space: normal;
    width: 100%;
`

const ImageRatio = Styled(Ratio)<RatioProps>`
    margin-bottom: 0.75rem;
    max-height: 20rem;
    
    ${Ratio.Inner} {
        align-items: center;
        display: flex;
        max-height: 20rem;
    }

    ${Ratio.Height} {
        padding-top: ${({ ratio }) => ((1 / ratio) * 100) + '%'};
    }
`

const Subtitle = Styled.h4`
    color: ${Color.LIGHT};
    font-size: 88%;
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
    opacity: 0.6;
    text-transform: uppercase;
`

const DataAttachment = Styled(Attachment)`

`

const Links = Styled.div`
    float: right;
`

const TitleLink = Styled(Link)`
    ${Gap('0.35rem')}
    ${Image(null)}
    ${OpacityHover()}
    ${Size('1.5rem')}
`

const DataItem: Type = ({ title, subtitle, description, path, id, link, tags, preview, intro, attachment, isActive, ratio, isStatic, links, order, imageHeight, ...props }) => {

    const Wrapper = isStatic ? React.Fragment : Reveal

    return (
        <Root {...props}>
            <Wrapper order={order}>
                <Container>
                    {path && (
                        <ImageRatio ratio={ratio!}>
                            {IsImage(path) ? (
                                <InnerImage path={path} ratio={ratio!} {...link} imageHeight={imageHeight} />
                            ) : (
                                <VideoPreview path={path} preview={preview} onClick={() => Urls.replace(link!)} isActive={isActive} />
                            )}
                        </ImageRatio>
                    )}
                    {links && (
                        <Links>
                            {links.map((link, i) => (
                                <TitleLink
                                    pathname={link}
                                    key={i}
                                    style={{ backgroundImage: `url(/Images/Layout/Attachment/${/\.pdf$/.test(link) ? 'Pdf' : (/github\.com/.test(link) ? 'Github' : 'Web')}.svg)` }} />
                            ))}
                        </Links>
                    )}
                    <Title>
                        {title}
                    </Title>
                    {subtitle && (
                        <Subtitle>
                            {subtitle}
                        </Subtitle>
                    )}
                    {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
                    {attachment && <DataAttachment data={attachment} />}
                    {tags && <TagGroup tags={tags} />}
                </Container>
            </Wrapper>
        </Root>
    )

}

DataItem.defaultProps = {
    ratio: 16 / 9
}

DataItem.Container = Container
DataItem.Attachment = DataAttachment
DataItem.Title = Title
DataItem.Main = ImageRatio
DataItem.Image = InnerImage
DataItem.Links = Links

export default DataItem