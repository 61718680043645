const Color = {

    DARKEST: '#121212',
    DARK: '#222A2A',
    DARK_MEDIUM: '#373737',
    MEDIUM: '#444',
    LIGHT_MEDIUM: '#777',
    LIGHT: '#CCC',
    LIGHTEST: '#EEE',

    RED: '#F33',
    GREEN: '#5C5',

    DARK_SEMI_TRANSPARENT: 'rgba(50, 50, 50, 0.8)',
    DARK_TRANSPARENT: 'rgba(60, 60, 60, 0.5)',

    BACKGROUND: '#252A25'

}

export default Color