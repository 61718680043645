import React from 'react'
import Styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import Hash from '../Constants/Hash'
import useElement from '../../System/Hooks/useElement'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    name: Hash
}

export type Type = React.FC<Props> & Static

const Root = Styled.div`
    transform: translateY(-3.5rem);
`

const Anchor: Type = ({ name, ...props }) => {

    const location = useLocation()
    const [isInitialized, setInitialized] = React.useState(false)
    const root = React.createRef<HTMLDivElement>()
    const { app } = useElement()

    React.useEffect(() => setInitialized(true), [setInitialized])

    React.useEffect(() => {
        if (name === location.hash.slice(1)) {
            const element = document.getElementById(name)
            const y = element!.getBoundingClientRect().top + app!.scrollTop
            app!.scrollTop = y
        }
    }, [location.hash, app, isInitialized, name])

    return (
        <Root {...props as any} ref={root} id={name} className='anchor' />
    )

}

export default Anchor