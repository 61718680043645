import React from 'react'
import Styled from 'styled-components'
import Scrollbar from '../../Layout/Components/Scrollbar'
import Duration from '../../Style/Constants/Duration'
import { Small } from '../../Style/Constants/Media'
import { LayoutIndent, Gap, OpacityHover, Size, Image } from '../../Style/Constants/Mixin'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    scrollbar?: { current: number, max: number, size: number, isAnimated?: boolean } // TODO: Scrollbar props.
    buttons?: [string, () => void][]
}

export type Type = React.FC<Props> & Static

const Root = Styled.section`
    ${LayoutIndent('right')}
    align-items: center;
    display: flex;
    height: 2rem;
    position: absolute;
    right: 1rem;
    top: -3rem;
    width: 15rem;
    
    & > * {
        ${Gap('0.5rem')}
    }
    
    ${Small`
        margin: 0 auto;
        margin-top: -1rem;
        margin-bottom: 1rem;
        position: relative;
        right: auto;
        top: auto;
        width: calc(100% - 2rem);
    `}
`

const SectionScrollbar = Styled(Scrollbar)`
    box-sizing: border-box;
    height: 0.2rem;
    padding: 0 1rem;
`

const AnimatedSectionScrollbar = Styled(SectionScrollbar)`
    ${Scrollbar.Inner} {
        transition: width ${Duration.MEDIUM}, left ${Duration.MEDIUM};
    }
`

const Button = Styled.button`
    ${Image(null, '80%')}
    ${OpacityHover()}
    ${Size('2rem')}
`

const SectionControls: Type = ({ buttons, scrollbar, ...props }) => {

    const renderButtons = () => {
        if (!buttons) {
            return null
        }

        return buttons.map(([name, handleClick], i) => (
            <Button onClick={handleClick} style={{ backgroundImage: `url(/Images/Topics/${name}.svg)` }} key={i} />
        ))
    }

    const renderScrollbar = () => {
        if (!scrollbar || (scrollbar.max <= scrollbar.size)) {
            return null
        }

        if (scrollbar.isAnimated) {
            return (
                <AnimatedSectionScrollbar {...scrollbar} />
            )
        }

        return (
            <SectionScrollbar {...scrollbar} />
        )
    }

    return (
        <Root {...props}>
            {renderScrollbar()}
            {renderButtons()}
        </Root>
    )

}

export default SectionControls