import { keyframes } from 'styled-components'


export const ZoomRotate = keyframes`
    0% {
        transform: rotate(0) scale(1);
    }
    
    18% {
        transform: rotate(0) scale(1);
    }
    
    33% {
        transform: rotate(0) scale(1.2);
    }
    
    48% {
        transform: rotate(0) scale(1);
    }
    
    66% {
        transform: rotate(0) scale(1);
    }
    
    100% {
        transform: rotate(90deg) scale(1);
    }

`