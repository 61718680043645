import React from 'react'
import Styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useAsync } from '@react-hook/async'

import TitleImage from '../Components/TitleImage'
import ContentType from '../Constants/ContentType'
import Pyramid from '../Components/Pyramid'
import Hash from '../../Routing/Constants/Hash'
import Timeline from '../../Layout/Components/Timeline'
import { getTopic } from '../Utils/Api'
import Cascade from '../../Layout/Components/Cascade'
import DataItem from '../../Layout/Components/DataItem'
import DragScroll from '../../Layout/Components/DragScroll'
import Grid from '../../Layout/Components/Grid'
import Loader from '../../Layout/Components/Loader'
import Ratio from '../../Layout/Components/Ratio'
import Anchor from '../../Routing/Components/Anchor'
import FixedSliderProvider from '../../Slider/Components/FixedSliderProvider'
import GallerySlider from '../../Slider/Components/GallerySlider'
import Slider3D from '../../Slider/Components/Slider3D'
import { View, Inner, SectionTitle } from '../../Style/Components/Components'
import Color from '../../Style/Constants/Color'
import { Small, Large } from '../../Style/Constants/Media'
import useWidthBreakpoint from '../../System/Hooks/UseWidthBreakpoint'
import Set from '../../Layout/Components/Set'
import Link from '../../Routing/Components/Link'
import MultiImage from '../../Layout/Components/MultiImage'
import { Size } from '../../Style/Constants/Mixin'

export interface Static {

}

export interface Props {

}

export type Type = React.FC<Props> & Static

const Root = Styled(View)`
   
`

const Section = Styled.section`

`

const GridItems = Styled(Grid)`
    margin-left: -0.5rem;
    width: calc(100% + 1rem);
    
    ${Small`
        margin-left: -0.25rem;
        width: calc(100% + 0.5rem);
    `}
`

const GridItem = Styled(DataItem)<any>`
    margin: 0.5rem;
    overflow: hidden;
    width: 16rem;
    
    ${({ isLarge }) => isLarge && `
        width: 20rem;
    `}
    
    ${({ isSmall }) => isSmall && `
        text-align: center;
        width: 10rem;
        
        ${DataItem.Image} {
            background-size: auto 100%;
        }
    `}
    
    ${DataItem.Main}, ${Ratio.Inner} {
        ${Large`
            overflow: hidden;
            max-height: 35vw;
        `}
        
        ${Small`
            margin-bottom: 0.75rem;
            max-height: 9rem;
            max-width: 100%;
        `}
    }
    
    ${Small`
        margin: 0.5rem 0.25rem;
        width: ${({ withText }: any) => withText ? '100%' : 'calc(50% - 0.5rem)'};
    `}
`

const Note = Styled.div`
    background-color: ${Color.MEDIUM};
    box-sizing: border-box;
    display: block;
    font-size: 95%;
    line-height: 1.5rem;
    margin: 4rem auto;
    margin-bottom: 3rem;
    padding: 0.5rem;
    width: calc(100% - 2rem);
    max-width: 40rem;
    
    ${Large`
        padding: 0.5rem;
        margin: 1rem auto;
    `}
`

const Content = Styled.div`
    background-color: ${Color.BACKGROUND};
    overflow: hidden;
    min-height: calc(100vh - 35rem);
`

const Links = Styled.div`
    aling-items: center;
    display: flex;
    justify-content: space-around;
    margin: 3rem auto;
`

const TopicLink = Styled(MultiImage)<any>`
    ${Size('6rem', '3rem')}
    text-align: center;

    ${MultiImage.Layer} {
        left: 50%;
        transform: translateX(-50%);
        width: 3rem;
    }

    &:after {
        content: "${props => props.title}";
        display: block;
        padding-top: 3.5rem;
    }
`

const TopicLinkSep = Styled.div`
    ${Size('0.1rem', '3rem')}
    background-color: ${Color.LIGHT};
    display: inline-block;
    opacity: 0.5;
`

const PlainWrapper = ({ children, ...props }: any) => children(props)

const TopicView: Type = ({ ...props }) => {

    const params = useParams<any>()
    const [{ value: topic, status }, call] = useAsync(() => getTopic(params.topic))
    React.useEffect(() => {
        call()
    }, [params.topic])

    const { isSmall, isLarge } = useWidthBreakpoint()

    const sections = React.useMemo(() => {
        const renderContent = (content: any, props: any) => {
            switch (content.content.type) {
                case ContentType.GALLERY_SLIDER:
                    return <GallerySlider {...props} />

                case ContentType.CASCADE:
                    return isSmall ? <GallerySlider {...props} /> : <Cascade {...props} />

                case ContentType.GRID:
                    return isSmall ? <GallerySlider {...props} /> : (
                        <Inner>
                            <GridItems {...props}>
                                {props.items.map((item: any, i: number) => <GridItem {...item} isLarge={props.isLarge}
                                                                        withText={!!item.description}
                                                                        isSmall={props.isSmall /* TODO: Autobind pros? */}
                                                                        key={i}/>)}
                            </GridItems>
                        </Inner>
                    )

                case ContentType.GALLERY_3D:
                    return isLarge ? <GallerySlider {...props} isLarge={true} key={1} /> : (
                        <Inner>
                            <Slider3D {...props} key={2} />
                        </Inner>
                    )

                case ContentType.TIMELINE:
                    return (
                        <DragScroll id={props.id}>
                            <Timeline {...props} />
                        </DragScroll>
                    )

                case ContentType.PYRAMID:
                    return (
                        <Inner>
                            <Pyramid items={content.items} />
                        </Inner>
                    )

                case ContentType.SET:
                    return (
                        <Inner>
                            <Set {...props} />
                        </Inner>
                    )
            }
        }

        return topic && topic.items!.map((section: any, i: number) => {
            const props = { key: i, id: section.id, items: [...section.items!], ...section.content }
            const Wrapper = section.content && section.content.detail && !(isSmall && section.content.type === ContentType.CASCADE) ? FixedSliderProvider : PlainWrapper

            return (
                <Section key={i}>
                    <Inner>
                        <SectionTitle>
                            <Anchor name={section.id as Hash} />
                            {section.title}
                        </SectionTitle>
                    </Inner>
                    {section.note && <Note dangerouslySetInnerHTML={{ __html: section.note }} />}
                    <Wrapper {...props}>
                        {props => renderContent(section, props) || null}
                    </Wrapper>
                </Section>
            )
        })
    }, [topic, isSmall, isLarge])

    return (
        <Root {...props}>
            <TitleImage topic={topic} topicId={params.topic} key={params.topic} />
            {!topic || status === 'loading' ? (
                <Content>
                    <Loader />
                </Content>
            ) : (
                <Content>
                    {topic.note && <Note dangerouslySetInnerHTML={{ __html: topic.note }} />}
                    {topic.links && (
                        <Links style={{ maxWidth: `${topic.links.length * 1.5 * 5}rem` }}>
                            {topic.links.map(({ name, path, image }: any, i: number) => (
                            <>
                                <TopicLink pathname={path} name={image} key={i} title={name} />
                                {i !== topic.links.length - 1 ? <TopicLinkSep /> : null}
                            </>
                            ))}
                        </Links>
                    )}
                    {sections}
                </Content>
            )}
        </Root>
    )

}

export default TopicView