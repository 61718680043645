import React from 'react'
import ReactDOM from 'react-dom'
import Styled from 'styled-components'
import useEvent from '@react-hook/event'
import { throttle } from 'throttle-debounce'

import { LinkData } from '../types'
import Link from './Link'
import useCurrentAnchor from '../Hooks/UseCurrentAnchor'
import useElement from '../../System/Hooks/useElement'
import Dimension from '../../Style/Constants/Dimension'
import Color from '../../Style/Constants/Color'
import Duration from '../../Style/Constants/Duration'
import { Size, LayoutIndent } from '../../Style/Constants/Mixin'
import ZIndex from '../../Style/Constants/ZIndex'

export interface Static {
    Link: string
}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    links: LinkData[]
}

export type Type = React.FC<Props> & Static

interface RootProps {
    isFixed?: boolean
}

interface LinkProps {
    isActive: boolean
}

const Root = Styled.nav<RootProps>`
    ${Size('100%', Dimension.NAV_HEIGHT, true)}
    -ms-overflow-style: none;
    -moz-scrollbars-none;
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    transition: background-color ${Duration.FAST};
    white-space: nowrap;
    z-index: ${ZIndex.ANCHOR_NAV};
    
    ${props => props.isFixed && `
        background-color: ${Color.DARKEST};
    `}
    
    &::-webkit-scrollbar {
        display: none;
    }
`

const NavLink = Styled(Link)<LinkProps>`
    display: inline-block;
    padding: 0 1rem;
    transition: background-color: ${Duration.MEDIUM};
    
    &:hover {
        background-color: ${Color.MEDIUM}
    }
    
    ${({ isActive }) => isActive && `
        background-color: ${Color.MEDIUM};
    `}
    
    &:first-of-type {
        ${LayoutIndent()}
    }
`

const AnchorNav: React.FC<Props> & Static = ({ links, ...props }) => {

    const anchor = useCurrentAnchor()
    const root = React.useRef<HTMLElement>()
    const fixedRoot = React.useRef<HTMLElement>()
    const [isFixed, setFixed] = React.useState(false)
    const { app, fixedNav } = useElement()

    useEvent(app!, 'scroll', throttle(200, () => setFixed(root.current!.getBoundingClientRect().top < 0)))

    React.useEffect(() => {
        if (fixedRoot.current) {
            if (anchor) {
                const link = document.querySelector(`[href$="#${anchor.id}"]`) as HTMLElement

                if (link) {
                    const width = window.innerWidth
                    const linkWidth = link.getBoundingClientRect().width
                    const start = link.offsetLeft
                    const end = start + linkWidth
                    fixedRoot.current.scrollLeft = end - width
                }
            }
        }
    }, [anchor])

    const renderedNav = React.useMemo(() => (
        links.map((link: LinkData, i: number) => (
            <NavLink
                hash={link.hash}
                isActive={anchor && anchor.id === link.hash}
                key={i}>
                {link.text}
            </NavLink>
        ))
    ), [links, anchor, isFixed])

    return (
        <>
            <Root {...props} ref={root as any}>
                {!isFixed && renderedNav}
            </Root>
            {isFixed && ReactDOM.createPortal((
                <Root {...props} isFixed={true} ref={fixedRoot as any}>
                    {renderedNav}
                </Root>
            ), fixedNav!)}
        </>
    )

}

AnchorNav.Link = NavLink

export default AnchorNav