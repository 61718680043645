import React from 'react'
import Styled from 'styled-components'

import Color from '../../Style/Constants/Color'
import { ZoomRotate } from '../../Style/Constants/Keyframe'
import { OpacityHover, Size, FlexCenter, Image } from '../../Style/Constants/Mixin'
import ZIndex from '../../Style/Constants/ZIndex'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    path: string
    preview?: string
    isActive?: boolean
}

export type Type = React.FC<Props> & Static

const Play = Styled.div`
    ${OpacityHover()}
    border-bottom: 1.5rem solid transparent; 
    border-left: 2.3rem solid ${Color.LIGHTEST}; 
    border-top: 1.5rem solid transparent;
    position: relative;
    z-index: ${ZIndex.VIDEO_PLAY};
`

const End = Styled.div`
    ${Size('5rem')}
    ${OpacityHover()}   
    background-color: ${Color.LIGHTEST};
    border-radius: 100%;
    z-index: ${ZIndex.VIDEO_PLAY};
    
    &:after {
        ${Size('2.5rem')}
        animation: ${ZoomRotate} 1.5s infinite linear;
        background-color: ${Color.DARKEST};
        border-radius: 0.5rem;
        content: "";
        display: block;
        margin: 1.25rem;
    }
`

const Root = Styled.button`
    ${Size('100%', '100%')}
    ${FlexCenter()}
    ${Image(null, 'cover')}
    position: relative;
    z-index: 1;
    
    &:after {
        ${OpacityHover()}
        background-color: ${Color.DARKEST};
        content: "";
        height: 100%;
        left: 0;
        opacity: 0.3;
        position: absolute;
        top: 0;
        width: 100%;
    }
    
    &:hover {
        &:after {
            opacity: 0.3;
        }
    
        ${Play}, ${End} {
            opacity: 1;
        }
    }
`

const VideoPreview: Type = ({ path, preview, isActive, ...props }) => {

    return (
        <Root {...props as any} style={{ backgroundImage: preview ? `url(${preview})` : `url(http://img.youtube.com/vi/${path}/0.jpg)` }}>
            {isActive ? <End /> : <Play />}
        </Root>
    )
}

export default VideoPreview