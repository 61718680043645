import React from 'react'
import Styled from 'styled-components'

import Topics from '../Constants/List'
import Config from '../../Utils/Constants/Config'
import Background from '../../Base/Components/Background'
import Dimension from '../../Style/Constants/Dimension'
import Link from '../../Routing/Components/Link'
import Color from '../../Style/Constants/Color'
import Contact from '../../Base/Components/Contact'
import Duration from '../../Style/Constants/Duration'
import { Title } from '../../Style/Components/Components'
import { Image, Size, OpacityHover } from '../../Style/Constants/Mixin'

interface Props extends React.ComponentPropsWithoutRef<'div'> {

}

const Root = Styled.section`

`

const Intro = Styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: space-between;
    overflow: hidden;
    padding-top: env(safe-area-insert-top, 1.5rem);
    padding-bottom: calc(2rem + env(safe-area-inset-bottom, 0));
    position: relative;
    
    h1 {
        display: none;
    }
`

const IntroCenter = Styled.div`
    align-items: center;
    display: flex;
    flex: 1 1 0;
    justify-align: center;

    & > div {
        display: inline-block;

        @media (hover: none), (max-width: ${Dimension.MEDIUM_WIDTH}) {
            margin-top: 2rem;
        }
    }
`

const IntroTitle = Styled(Title)`
    display: block !important;
    margin: 0 auto;
`

const IntroSubtitle = Styled.div`
    box-sizing: border-box;
    display: flex;
    font-size: 130%;
    font-weight: normal;
    justify-content: space-between;
    margin: 0;
    margin-top: 1rem;
    padding: 0 0.5rem;
    text-align: center;

    @media (hover: none), (max-width: ${Dimension.MEDIUM_WIDTH}) {
        font-size: 100%;
        margin: 0 auto;
        margin-top: 1rem;
        max-width: calc(100% - 2rem);
    }
`

const NavLink = Styled(Link)`
    ${Image(undefined, 'auto 55%')}
    ${Size('3.5rem')}
    ${OpacityHover()}
    color: transparent;
    display: block;
    position: relative;
    transition: color ${Duration.FAST}, opacity ${Duration.FAST};
    
    @media (hover: none), (max-width: ${Dimension.MEDIUM_WIDTH}) {
        ${Size('auto', '2.5rem', true)}
        background-position: left center;
        background-size: auto 70%;
        color: ${Color.LIGHT};
        margin: 0 0.5rem;
        padding-left: 2.5rem;
    }
`

const NavLinkLabel = Styled.div`
    font-size: 90%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateX(-100%) translateY(-50%);
    
    @media (hover: none), (max-width: ${Dimension.MEDIUM_WIDTH}) {
        position: relative;
        pointer-events: all;
        top: auto;
        transform: none;
    }
`

const Nav = Styled.nav`
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    
    &:hover {
        ${NavLink} {
            color: ${Color.LIGHTEST};
        }
    }
    
    @media (hover: none), (max-width: ${Dimension.MEDIUM_WIDTH}) {
        ${Size('100%', 'auto')};
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 0.5rem;
    }
`

const HomeView: React.FC<Props> = ({ ...props }) => {

    const foreground = React.useRef<HTMLElement>()

    const renderedTopics = React.useMemo(() => (
        Topics.map((topic, i) => (
            <NavLink pathname={topic.id} style={{ backgroundImage: `url(/Images/Topics/${topic.id}/Preview.svg)` }} key={i}>
                <NavLinkLabel>
                    {topic.title}
                </NavLinkLabel>
            </NavLink>
        ))
    ), [])

    return (
        <Root {...props}>
            <Intro>
                <Background />
                <Nav>
                    {renderedTopics}
                </Nav>
                <div />
                <IntroCenter>
                    <div>
                        <IntroTitle ref={foreground as any}>
                            {Config.name}
                        </IntroTitle>
                        <IntroSubtitle>
                            {'1997 • Pardubice • INTP • 5/5w4'.split('').map((char, i) => <span key={i}>{char}</span>)}
                        </IntroSubtitle>
                    </div>
                </IntroCenter>
                <Contact />
            </Intro>
        </Root>
    )

}

export default HomeView