import React from 'react'
import useEvent from '@react-hook/event'
import { throttle } from 'throttle-debounce'
import { useLocation } from 'react-router-dom'

import useElement from '../../System/Hooks/useElement'

const UseCurrentAnchor = (): HTMLElement => {
    const { app } = useElement()
    const location = useLocation()
    const [anchors, setAnchors] = React.useState([])

    React.useEffect(() => {
        setTimeout(() => {
            setAnchors([].slice.call(document.getElementsByClassName('anchor')))
        }, 200)
    }, [location.pathname])

    const getOffsetsTop = () => {
        return anchors.map((anchor: any) => anchor!.getBoundingClientRect().top + app!.scrollTop)
    }

    const getActive = (y = 0) => {
        if (!y) {
            return 0
        }

        for (let i = offsetsTop.length - 1; i >= 0; i-- in offsetsTop) {
            if (offsetsTop[i] - y - window.innerHeight / 2 <= 0) {
                return i
            }
        }
    }

    const [offsetsTop, setOffsetsTop] = React.useState([])
    const [active, setActive] = React.useState(0)

    React.useEffect(() => {
        setOffsetsTop(getOffsetsTop() as any)
    }, [anchors])

    React.useEffect(() => {
        handleChange()
    }, [offsetsTop])

    const handleChange = () => {
        const newActive = getActive(app!.scrollTop)

        if (newActive !== active) {
            setActive(newActive!)
        }
    }

    useEvent(app!, 'scroll', throttle(200, handleChange))
    useEvent(window, 'resize', throttle(200, handleChange))

    return anchors[active]
}

export default UseCurrentAnchor