import React from 'react'
import Styled, { css } from 'styled-components'

import Color from '../../Style/Constants/Color'
import { Large } from '../../Style/Constants/Media'
import { LayoutIndent, Size, Image } from '../../Style/Constants/Mixin'
import ZIndex from '../../Style/Constants/ZIndex'
import DateTime from '../../Utils/Utils/DateTime'
import DataItem from './DataItem'

export interface Static {

}

interface Item {
    title: string // Title of event.
    subtitle?: string // Subtitle of event. (default no subtitle)
    description?: string // Description of event. (default no description)
    image?: string // Image of event. (default no image)
    start: number // Timestamp of event start.
    end?: number // Timestamp of event end. (default start)
    group?: number
}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    items: Item[]
    start: number // Timestamp of timeline start. (default start of the oldest event)
    end: number // Timestamp of timeline end. (default end of the newest event)
    tickSize: (value: number) => number // Get count of milliseconds between two ticks. (default 1/4 of full width) // TODO
    minorTicksCount: number // Count of minor ticks in tick. (default 12)
    collapse: number // Min size of empty space between events, that will be collapsed. (default no collapse)
    renderItem: (item: Item) => React.ReactElement // Render custom item. (default no custom rendered)
    eventWidth: number // Width of event in minor ticks. (default 14)
    minorTickWidth: number // Width of tick space in minor rem. (default 1)

    formatter?: (value: number) => string // Format time values and units.
    tickFormatter?: (value: number) => string // Format time values and units only in tick label.
    spaceFormatter?: (value: number) => string // Format time values and units only in empty space.
    eventFormatter?: (value: number) => string // Format time values and units only in event time.

    seasons?: Event[]
}

export type Type = React.FC<Props> & Static

interface Event {
    start: number
    end: number
    element: React.ReactElement
    startColumn: number
    endColumn: number
    column: number
    isTop: boolean
    offset: number
    group: number
}

type Interval = any

interface EventProps {
    isTop?: boolean
}

enum IntervalType {
    BASIC,
    EMPTY,
    POINT
}

const groupColors = [Color.LIGHT, '#5F5', '#F55', '#C5F', '#5FF', '#FF5', '#FAA', '#AF5', '#F55', '#FF5']

const Root = Styled.div`
    ${LayoutIndent()}
    ${LayoutIndent('right')}
    display: inline-grid;
    grid-template-rows: auto auto 0.6rem 0.6rem auto;
    padding: 0 2rem;
    white-space: nowrap;
`

const Tick = Styled.div`
    ${Size('1px', '0.6rem')}
    background-color: ${Color.LIGHT};
    grid-row: 3 / 5;
    margin-top: 0.3125rem;
    position: relative;
    z-index: ${ZIndex.TIMELINE_TICK};
`

const MajorTick = Styled(Tick)`
    height: 1.25rem;
    margin-top: 0;
`

const TickValue = Styled.div`
    margin-top: -1.5rem;
    position: absolute;
    transform: translateX(-50%);
`

const EventRoot = Styled.div<any>`
    background-color: ${({group}: any) => groupColors[group || 0]};
    height: 0.5rem;
    opacity: 0.6;
`

const EventBody = Styled.div<EventProps>`
    align-items: flex-end;
    display: flex;
    margin: 0 auto;
    padding-bottom: 4rem;
    position: relative;
    width: 100%;
    z-index: ${ZIndex.TIMELINE_EVENT};
    
    ${Large`
        padding-bottom: 3rem;
    `}
    
    & > * {
        width: 100% !important;
    }
    
    ${({ isTop }) => !isTop && css`
        align-items: flex-start;
        padding-bottom: 0;
        padding-top: 4rem;
        
        ${Large`
            padding-bottom: 0;
            padding-top: 3rem;
        `}
    `}
`

const Space = Styled.div`
    ${Image('Layout/Timeline/Space.svg', '90% auto', 'center top')}
    ${Size('5rem', '2rem')}
    grid-row: 3 / 5;
    opacity: 0.5;
    padding-top: 3rem;
    transform: translateY(-60%) translateX(-50%);
    text-align: center;
`

const DiagonalLine = Styled.div`
    height: 4.2rem;
    
    ${Large`
        height: 3.2rem;
    `}
`

const Event = Styled(DataItem)`

    ${DataItem.Container} {
        background-color: ${Color.MEDIUM};
        padding: 0.5rem
    }
    
    ${DataItem.Title} {
        font-size: 110%;
        white-space: normal;
    }
    
    ${DataItem.Links} {
        float: none;
    }
`

const Season = Styled(DataItem)<any>`
    ${DataItem.Container} {
        font-size: 90%;
        grid-row: 1 / 2;
        margin-bottom: 1.5rem;
        padding-bottom: 1.25rem;
        position: relative;
        z-index: 1000;
        
        &:after {
            ${Size('100%', '0.2rem')}
            background-color: ${({ group }: any) => groupColors[group || 0]};
            bottom: 0;
            content: "";
            left: 0;
            opacity: 0.6;
            position: absolute;
        }
        
    }
`

const getFirst = <E, T>(evaluate: (val1: T, val2: T) => T, items: E[], accessor: (item: E) => T) => {
    let first: any = undefined
    items.map(item => first = first === undefined ? accessor(item) : evaluate(accessor(item), first))
    return first
}

const isIn = (event: Event, start: number, end: number) => (
    (event.start >= start && event.end < end) ||
    (event.start < start && event.end >= end) ||
    (event.start < start && event.end >= start) ||
    (event.start < end && event.end >= start)
)

const getRelative = (value: number, min: number, max: number, columnsCount: number) => {
    return columnsCount * (value - min) / (max - min) + 1
}

// TODO: Independent to year.
const getAligned = (value: number, getStep: (value: number) => number, round: (value: number) => number) => (
    new Date(new Date(value).getFullYear() + round(0.5), 0, 1, 0, 0, 0, 0).getTime()//round(value / step) * step
)

const normalizeEvents = (items: Item[], renderer: (item: Item) => React.ReactElement): Event[] => (
    items.map((item, i) => ({
        start: item.start,
        end: item.end === undefined ? item.start : item.end,
        element: renderer(item),
        group: item.group,
        order: i
    })).sort((event1, event2) => event1.start - event2.start) as any
)

const createIntervals = (events: Event[], start: number, end: number, tickSize: (value: number) => number, minorTickSize: number, collapse: number, intervalPadding: number): Interval[] => {
    const intervals = []
    let isOpenedInterval = false
    let startColumn = 0
    let empty = 0

    for (let i = start; i < end; i += tickSize(i)) {
        const isEmpty = !events.find(event => isIn(event, i, i + tickSize(i)))

        if (isEmpty) {
            empty++
        } else {
            const lastInterval: any = intervals[intervals.length - 1]

            if (empty >= collapse) {
                isOpenedInterval = false

                if (lastInterval) {
                    startColumn += Math.round((lastInterval.end - lastInterval.start) / minorTickSize + intervalPadding * 2)
                    lastInterval.endColumn = startColumn
                } else {
                    intervals.push({
                        start,
                        end: start,
                        startColumn,
                        endColumn: intervalPadding,
                        type: IntervalType.POINT
                    })
                    startColumn += intervalPadding
                }

                intervals.push({
                    start: lastInterval ? lastInterval.end : start,
                    end: i,
                    startColumn,
                    endColumn: startColumn,
                    type: IntervalType.EMPTY
                })
            }

            if (isOpenedInterval) {
                lastInterval.end = i + tickSize(i)
            } else {
                intervals.push({
                    start: empty > 0 && empty < collapse ? start : i,
                    end: i + tickSize(i),
                    startColumn,
                    type: IntervalType.BASIC
                })
                isOpenedInterval = true
            }

            empty = 0
        }
    }

    const lastInterval = intervals[intervals.length - 1]
    startColumn += Math.round((lastInterval.end - lastInterval.start) / minorTickSize + intervalPadding * 2)
    lastInterval.endColumn = startColumn

    if (lastInterval.end - end !== 0) {
        intervals.push({
            start: lastInterval.end,
            end,
            startColumn: lastInterval.endColumn,
            endColumn: lastInterval.endColumn,
            type: IntervalType.EMPTY
        })
        intervals.push({
            start: end,
            end,
            startColumn: lastInterval.endColumn + intervalPadding + 1,
            endColumn: lastInterval.endColumn + intervalPadding * 2 + 1,
            type: IntervalType.POINT
        })
    }

    return intervals as any
}

const fillIntervals = (intervals: Interval[], events: Event[], minorTickSize: number, halfEventWidth: number): void => {
    const groups = [[{ column: -Infinity, offset: 0 }], [{ column: -Infinity, offset: 0 }]]

    for (const interval of intervals) {
        if (interval.type === IntervalType.BASIC) {
            interval.events = events.filter(event => isIn(event, interval.start, interval.end))
            const columnsCount = (interval.end - interval.start) / minorTickSize

            // Set positions and fix positions forward.
            for (const event of interval.events) {
                event.startColumn = Math.floor(getRelative(event.start, interval.start, interval.end, columnsCount)) + interval.startColumn
                event.endColumn = Math.ceil(getRelative(event.end, interval.start, interval.end, columnsCount)) + interval.startColumn
                event.column = Math.floor((event.startColumn + event.endColumn) / 2)

                event.isTop = groups[0][0].column + groups[0][0].offset <= groups[1][0].column + groups[1][0].offset
                const localLastEvents: any = groups[event.isTop ? 0 : 1]
                event.offset = Math.min(halfEventWidth, Math.max(0, (localLastEvents[0].column + localLastEvents[0].offset + halfEventWidth + 1) - (event.column - halfEventWidth)))
                localLastEvents.unshift(event)
            }

            for (const events of groups) {
                for (let i = 0; i < events.length - 1; i++) {
                    const event = events[i]
                    const lastEvent = events[i + 1]
                    const overlap = Math.max(0, (lastEvent.column + halfEventWidth + 1 + lastEvent.offset) - (event.column - halfEventWidth + event.offset))
                    lastEvent.offset -= Math.min(overlap, event.column + halfEventWidth + 1)
                }
            }
        }
    }
}

const Timeline: Type = ({
                            items,
                            start: _start,
                            end: _end,
                            tickSize,
                            minorTicksCount,
                            collapse,
                            renderItem,
                            eventWidth,
                            minorTickWidth,
                            formatter,
                            tickFormatter,
                            spaceFormatter,
                            eventFormatter,
                            seasons,
                            ...props
                        }) => {

    const minorTickSize = tickSize(0) / minorTicksCount
    const halfEventWidth = Math.ceil(eventWidth! / 2)
    const intervalPadding = halfEventWidth

    const itemRenderer = React.useMemo(() => (
        (renderItem as any) ? renderItem : (item: any) => (
            <Event
                {...item}
                dateFormat='M/R'
                subtitle={DateTime.getInterval(item.start, item.end, eventFormatter || formatter) + (item.subtitle ? ', ' + item.subtitle : '')} />
        )
    ), [eventFormatter, formatter])

    const [start, end] = React.useMemo(() => ([
        _start === undefined ? getAligned(getFirst<Item, number>(Math.min, items, item => item.start), tickSize, Math.floor) : _start,
        _end === undefined ? getAligned(getFirst<Item, number>(Math.max, items, item => item.end === undefined ? item.start : item.end), tickSize, Math.ceil) : _end
    ]), [_start, _end])

    const intervals: Interval[] = React.useMemo(() => {
        const events = normalizeEvents(items, itemRenderer)
        const intervals = createIntervals(events, start, end, tickSize, minorTickSize, collapse, intervalPadding)
        fillIntervals(intervals, events, minorTickSize, halfEventWidth)
        return intervals
    }, [items, start, end, tickSize, minorTickSize])

    const renderEvents = (interval: Interval) => (
        interval.events.map((event: any, i: number) => {
            const lastColumn = { top: -Infinity, bottom: -Infinity }
            lastColumn[event.isTop ? 'bottom' : 'top'] = event.endColumn

            let transformations = [event.isTop ? 'translateY(-100%)' : 'translateY(0.5rem)']

            if ((event.isTop && event.offset > 0) || (!event.isTop && event.offset <= 0)) {
                transformations.push('rotateY(180deg)')
            }

            const isReversed = (event.endColumn - event.startColumn) % 2 || (event.endColumn - event.startColumn) < 2
            const sign = event.isTop ? 1 : -1

            if (event.offset >= 0) {
                transformations.push(isReversed ? `translateX(${-minorTickWidth / 2 * sign}rem)` : `translateX(0)`)
            } else {
                transformations.push(isReversed ? `translateX(${minorTickWidth / 2 * sign}rem)` : `translateX(${-minorTickWidth * sign}rem)`)
            }

            return (
                <React.Fragment key={i}>
                    <EventBody isTop={event.isTop} style={{
                        gridColumn: `${event.column + event.offset} / ${event.column + eventWidth + event.offset}`,
                        gridRow: event.isTop ? '2 / 3' : '5 / 6'
                    }}>
                        {event.element}
                    </EventBody>
                    <EventRoot group={event.group as any} style={{
                        gridColumn: `${event.startColumn + intervalPadding} / ${event.endColumn + intervalPadding}`,
                        gridRow: event.isTop ? '3 / 4' : '4 / 5'
                    }} />
                    <DiagonalLine style={{
                        backgroundImage: `linear-gradient(to top right, transparent calc(50% - 2px), ${Color.LIGHT_MEDIUM}, transparent calc(50% + 2px))`,
                        gridColumn: `${event.startColumn + Math.floor((event.endColumn - event.startColumn) / 2) + intervalPadding} / ${event.column + event.offset + intervalPadding}`,
                        gridRow: event.isTop ? '3 / 4' : '4 / 5',
                        transform: transformations.join(' ')
                    }} />
                </React.Fragment>
            )
        })
    )

    const renderTime = (interval: Interval) => {
        const result = []
        let column = 0

        for (let i = interval.start; i < interval.end + tickSize(interval.end); i += tickSize(i)) {
            result.push(
                <MajorTick key={column}
                           style={{ gridColumn: `${++column + intervalPadding + interval.startColumn} / ${column + 1 + intervalPadding + interval.startColumn}` }}>
                    <TickValue>
                        {((tickFormatter as any) || formatter)(i)}
                    </TickValue>
                </MajorTick>
            )

            if (i < interval.end) {
                for (let j = 0; j < minorTicksCount - 1; j++) {
                    result.push(
                        <Tick
                            key={column}
                            style={{ gridColumn: `${++column + intervalPadding + interval.startColumn} / ${column + 1 + intervalPadding + interval.startColumn}` }} />
                    )
                }
            }
        }

        return result
    }

    const renderedIntervals = React.useMemo(() => {
        return intervals.map((interval, i) => {
            if (interval.type === IntervalType.EMPTY) {
                return (
                    <Space
                        key={i}
                        style={{ gridColumn: `${interval.startColumn + 1} / ${interval.startColumn + minorTickWidth + 1}` }}>
                        {((spaceFormatter as any) || formatter)(interval.end - interval.start)}
                    </Space>
                )
            } else if (interval.type === IntervalType.BASIC) {
                return (
                    <React.Fragment key={i}>
                        {renderTime(interval)}
                        {renderEvents(interval)}
                    </React.Fragment>
                )
            } else if (interval.type === IntervalType.POINT) {
                return (
                    <MajorTick style={{ gridColumn: `${interval.startColumn} / ${interval.startColumn}` }} key={i}>
                        <TickValue>
                            {((tickFormatter as any) || formatter)(interval.start)}
                        </TickValue>
                    </MajorTick>
                )
            }
        })
    }, [intervals])

    const isIn = (time: number, interval: Interval) => interval.start <= time && interval.end > time
    const getInterval = (time: number, intervals: Interval[]) => intervals.find(interval => isIn(time, interval))

    const getColumn = (time: number, intervals: Interval[]) => {
        const interval = getInterval(time, intervals)
        return Math.floor((time - interval.start) / (interval.end - interval.start) * (interval.endColumn - interval.startColumn) + interval.startColumn + 1) // TODO: floor/ceil in parameter.
    }

    const renderedSeasons = React.useMemo(() => {
        if (!seasons) {
            return null
        }

        return seasons.map((season, i) => (
            <Season style={{ gridColumn: `${getColumn(season.start, intervals)} / ${getColumn(season.end, intervals)}`, gridRow: '1 / 2' }} {...season} key={i} />
        ))
    }, [intervals, seasons])

    const lastInterval = intervals[intervals.length - 1]

    return (
        <Root {...props} style={{ gridTemplateColumns: `repeat(${lastInterval.endColumn}, ${minorTickWidth}rem)` }}>
            {renderedIntervals}
            {renderedSeasons}
        </Root>
    )

}


const type = {
    YEAR: {
        tickSize: (value: number) => DateTime.addYears(value, 1) - value,
        minorTicksCount: 12,
        formatter: (value: number) => DateTime.formatYearsDistance(value),//formatDistanceStrict(0, value, { locale: cs }),
        eventFormatter: (value: number) => DateTime.formatMonthYear(value),
        tickFormatter: (value: number) => new Date(value).getFullYear().toString()
    }
}


Timeline.defaultProps = {
    collapse: 1,
    eventWidth: 14,
    minorTickWidth: 1,
    end: DateTime.getNextYear(),
    ...type.YEAR
}

export default Timeline