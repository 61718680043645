export const getTopic = async (id: string) => {
    return (await import(`../Data/${id}`)).default
}

export const getItems = async (topicId: string, sectionId: string) => {
    const topic = await getTopic(topicId)
    if (!topic) return null
    const section = topic.items.find((section: any) => section.id === sectionId)
    if (!section) return null
    return section.items
}