module Arrays {

    type FunctionSelector<T> = (item: T, index: number) => boolean
    type ValueSelector<T> = T
    type Selector<T> = FunctionSelector<T> | ValueSelector<T>

    /**
     * Move item in array to the position. Doesn't modify old array, instead returns copy of array.
     * @param array Source array.
     * @param selector Predicate for item to move. Could be function or value.
     * @param index Item will be moved to this index.
     * @returns Reorganized array.
     */
    export const move = <T extends any>(array: T[], selector: Selector<T>, index: number): T[] => {
        const predicate = typeof selector === 'function' ? (selector as FunctionSelector<T>) : (item: T, i: number) => item === selector
        const oldIndex = array.findIndex(predicate)
        const item = array[oldIndex]
        const result = [...array].filter((item, i) => i !== oldIndex)

        if (item) {
            result.splice(index, 0, item)
        }

        return result
    }

}

export default Arrays