import React from 'react'
import Styled from 'styled-components'

import TagGroup from './TagGroup'
import Ratio from './Ratio'
import VideoPreview from './VideoPreview'
import Reveal from './Reveal'
import Link from '../../Routing/Components/Link'
import { LinkData } from '../../Routing/types'
import { ItemTitle } from '../../Style/Components/Components'
import { Medium } from '../../Style/Constants/Media'
import { Size, FlexCenter, Image } from '../../Style/Constants/Mixin'

export interface Static {
    LeftItem: string
    RightItem: string
}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    startFromRight?: boolean // TODO: Implement.
    items: {
        title: string
        description?: string
        path?: string
        video?: string
        videoPreview?: string
        tags?: LinkData[]
    }[]
    size?: number
    ratio?: number
}

export type Type = React.FC<Props> & Static

interface RootProps {
    size: number
}

interface ImageContainerProps {
    path: string
    ratio: number
    size: number
}

interface WithSizeProps {
    size: number
}

const ImageContainer = Styled(Ratio)<ImageContainerProps>`
    ${({ path }) => Image(path, '95% auto')}
    ${Size('45%', 'auto')}
    background-image: url(${({ path }) => path});
    max-height: 100%;
    
    ${Ratio.Height} {
        padding-top: ${({ ratio }) => ratio * 100}%;
    }
    
    @media (max-width: ${({ size }) => size === 1 ? 60 : 80}rem) {
        margin: 0 auto;
        width: 100%;
        max-width: 20rem;
    }
    
    ${Medium`
        max-width: 12rem;
    `}
`

const Title = Styled(ItemTitle)`
    margin-bottom: 0;
`

const Text = Styled.div<WithSizeProps>`
    ${Size('55%', 'auto')}
    ${FlexCenter()}
    box-sizing: border-box;
    justify-content: left;
    position: relative;
    
    @media (max-width: ${({ size }) => size === 1 ? 60 : 80}rem) {
        padding: 0 !important;
        text-align: left !important;
        top: 0;
        transform: none;
        width: 100%;
    }
`

const InnerText = Styled.div<WithSizeProps>`
    width: 100%;
    max-width: 33rem;
    
    @media (max-width: ${({ size }) => size === 1 ? 60 : 80}rem) {
        max-width: none;
    }
`

const Description = Styled.p`
    display: block;
    line-height: 1.6rem;
    margin-top: 1rem;
`

const VideoRatio = Styled(Ratio)`
    &, ${Ratio.Inner} {
        max-height: 15rem;
    }
`

const VideoLink = Styled(Link)`
    margin-top: 2rem;
    width: 25rem;
    max-width: 100%;
    
    @media (max-width: 36rem) {
        display: block;
        width: 100%;
    }
`

const Item = Styled.div<WithSizeProps>`
    ${({ size }) => Size((100 / size) + '%', 'auto')}
    box-sizing: border-box;
    display: inline-block;
    margin-top: 2rem;
    overflow: hidden;
    
    & > div {
        ${Size()}
        display: flex;
    }
    
    @media (max-width: ${({ size }) => size === 1 ? 60 : 80}rem) {
        height: auto;
        
        &:nth-of-type(2n) {
            padding-left: 0.5rem;
        }
        
        &:nth-of-type(2n + 1) {
            padding-right: 0.5rem;
        }
        
        & > div {
            display: block;
        }
    }
    
    @media (max-width: 36rem) {
        display: block;
        padding: 0 !important;
        width: 100%;
    }
`

const LeftItem = Styled(Item)`
    ${Text} {
        padding-left: 2rem;
    }
    
    & > div {
        flex-direction: row;
    }
`

const RightItem = Styled(Item)`
    ${Text} {
        justify-content: flex-end;
        padding-right: 2rem;
        text-align: right;
    }
    
    & > div {
        flex-direction: row-reverse;
    }
`

const Root = Styled.div<RootProps>`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 1rem;
    width: 65rem;
    max-width: 100%;
    
    ${({ size }) => size === 2 && `
        width: 100%;
    `}
`

const Cascade: Type = ({ startFromRight, items, size, ratio, ...props }) => {

    const renderVideo = (item: any) => {
        if (!item.video) {
            return null
        }

        return (
            <VideoLink {...item.link}>
                <VideoRatio>
                    <VideoPreview {...item} preview={item.videoPreview} path={item.video} />
                </VideoRatio>
            </VideoLink>
        )
    }

    const renderedItems = React.useMemo(() => (
        items.map((item, i) => {
            const Component = i % (size! * 2) < size! ? LeftItem : RightItem

            return (
                <Component key={i} size={size!}>
                    <Reveal>
                        <>
                            <ImageContainer path={item.path!} ratio={ratio!} size={size!} />
                            <Text size={size!}>
                                <InnerText size={size!}>
                                    <Title>
                                        {item.title}
                                    </Title>
                                    <Description dangerouslySetInnerHTML={{ __html: item.description } as any} />
                                    {renderVideo(item)}
                                    {item.tags && <TagGroup tags={item.tags} />}
                                </InnerText>
                            </Text>
                        </>
                    </Reveal>
                </Component>
            )
        })
    ), [startFromRight, items, size])

    return (
        <Root {...props} size={size!}>
            {renderedItems}
        </Root>
    )

}

Cascade.LeftItem = LeftItem
Cascade.RightItem = RightItem

Cascade.defaultProps = {
    size: 1,
    startFromRight: false,
    ratio: 2
}

export default Cascade