import React from 'react'

import { ResponsiveData } from '../types'
import useWindowSize from '../../System/Hooks/UseWindowSize'

const UseResponsiveData = <T>(data: ResponsiveData<T>): T => {
    const { width, height } = useWindowSize(true)

    return React.useMemo(() => {
        const isRem = (value: string) => /^[0-9.]+rem$/.test(value.toString())

        if (!(Array.isArray(data) && data.find(item => Array.isArray(item) && ((item.length === 2 && isRem(item[0])) || (item.length === 3 && isRem(item[0]) && isRem(item[1])))))) {
            return data as T
        }

        for (const item of data) {
            if (Array.isArray(item)) {
                const maxWidth = parseFloat(item[0])

                if (item.length === 3) {
                    const maxHeight = parseFloat(item[1])

                    if (maxWidth && maxHeight) {
                        if (width <= maxWidth && height <= maxHeight) {
                            return item[2]
                        }
                    } else if (maxWidth) {
                        if (width <= maxWidth) {
                            return item[2]
                        }
                    } else if (maxHeight) {
                        if (height <= maxHeight) {
                            return item[2]
                        }
                    } else {
                        return item[2]
                    }
                } else if (item.length === 2) {
                    if (width) {
                        if (width <= maxWidth) {
                            return item[1]
                        }
                    } else {
                        return item[1]
                    }
                }
            } else {
                return item as any
            }
        }

    }, [data, width, height])
}

export default UseResponsiveData