import { QuerySet } from '../types'
import Query from '../Constants/Query'

module Queries {

    /**
     * Get parameter from query string.
     * @param source Source query string.
     * @param name Name of parameter.
     * @returns Value of parameter.
     */
    export const get = (source: string, name: Query): Query => {
        return new URLSearchParams(source).get(name) as Query
    }

    /**
     * CHeck if parameter is in query string.
     * @param source Source query string.
     * @param name Name of parameter.
     * @returns Parameter is in query string.
     */
    export const has = (source: string, name: Query): boolean => {
        return new URLSearchParams(source).has(name)
    }

    /**
     * Delete parameter from query string.
     * @param source Source query string.
     * @param name Name of parameter.
     */
    export const remove = (source: string, name: Query): void => {
        new URLSearchParams(source).delete(name)
    }

    /**
     * Merge changes to source query string.
     * @param source Source query string.
     * @param changes Query object changes.
     * @returns Merged query string.
     */
    export const merge = (source: string, changes: QuerySet): string => {
        const params = new URLSearchParams(source)

        for (const i in changes) {
            if (changes[i] === null) {
                params.delete(i)
            } else {
                params.set(i, changes[i])
            }
        }

        return params.toString()
    }

    /**
     * Convert query string to query object.
     * @param source Source query string.
     * @returns Query object.
     */
    export const parse = (source: string): QuerySet => {
        const result: Record<string, string> = {}

        new URLSearchParams(source).forEach((value: string, key: string) => {
            result[key] = value
        })

        return result
    }

    /**
     * Convert query object to query string.
     * @param source Source query object.
     * @returns Query string.
     */
    export const toString = (source: QuerySet): string => {
        return new URLSearchParams(source).toString()
    }

}

export default Queries

export { default as Query } from '../Constants/Query'