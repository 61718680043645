import React from 'react'
import Styled from 'styled-components'

import { LinkData } from '../../Routing/types'
import Color from '../../Style/Constants/Color'
import { Size, OpacityHover, Image } from '../../Style/Constants/Mixin'
import ZIndex from '../../Style/Constants/ZIndex'
import Arrays from '../../Utils/Utils/Arrays'
import HoverArea from './HoverArea'
import Link from '../../Routing/Components/Link'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    links: LinkData[][]
    current?: string[]
}

export type Type = React.FC<Props> & Static

const Root = Styled.nav<any>`
    ${Size('100%', '1.5rem', true)}
    position: relative;
`

const Layer = Styled.div`
    background-color: ${Color.DARK_SEMI_TRANSPARENT};
`

const HoverNav = Styled(HoverArea)`
    display: inline-block;
`

const EmptyNav = Styled.div`
    display: inline-block;
    
    &:first-of-type {
        margin-left: -1.5rem;
    }
`

const HoverLink = Styled(Link)`
    ${OpacityHover(0.7)}
    box-sizing: border-box;
    color: #FFF;
    display: block;
    padding: 0.5rem 1.5rem;
    white-space: nowrap;
    width: 100%;
`

const Arrow = Styled.div`
    ${Image('Nav/PageLocationArrow.svg', '80%')}
    ${Size('1.5rem')}
    margin-top: 0.5rem;
    position: absolute;
    transform: translateX(-50%);
    z-index: ${ZIndex.HIERARCHICAL_NAV_ARROW};
`

const HierarchicalNav: Type = ({ links, current, ...props }) => {

    const orderedLayers = React.useMemo(() => {
        if (!current) {
            return links
        }

        const orderedLinks = [...links]

        for (const i in current) {
            if (typeof current[i] === 'string') {
                orderedLinks[i] = Arrays.move<LinkData>(orderedLinks[i], item => item.pathname === current[i], 0)
            }
        }

        return orderedLinks
    }, [links, current])

    const renderLayerLinks = (layer: LinkData[]) => (
        layer.map(({ text, ...link }, i) => (
            <HoverLink {...link} key={i}>
                {text}
            </HoverLink>
        ))
    )

    const renderedLinks = React.useMemo(() => (
        orderedLayers.map((layer, i) => {
            const { text, ...props } = layer[0]
            const mainLink = layer.length > 0 ? <HoverLink {...props}>{text}</HoverLink> : null

            if (layer.length > 1) {
                return (
                    <HoverNav main={<><Arrow />{mainLink}</>} key={i}>
                        <Layer>
                            {renderLayerLinks(layer)}
                        </Layer>
                    </HoverNav>
                )
            } else if (layer.length > 0) {
                return (
                    <EmptyNav key={i}>
                        {mainLink}
                    </EmptyNav>
                )
            }
        })
    ), [links])

    return (
        <Root {...props}>
            {renderedLinks}
        </Root>
    )

}

export default HierarchicalNav