import React from 'react'
import Styled from 'styled-components'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {

}

export type Type = React.FC<Props> & Static

const Root = Styled.img<any>`
    width: 4rem;
    height: 4rem;
    display: block;
    margin: 5rem auto;
`

const Loader: Type = ({ ...props }) => (
    <Root src='/Images/Controls/Loader.png' />
)


export default Loader