import React from 'react'
import Styled from 'styled-components'

import MultiImage from '../../Layout/Components/MultiImage'
import { Title } from '../../Style/Components/Components'
import { Large, Medium, Small } from '../../Style/Constants/Media'
import { Size } from '../../Style/Constants/Mixin'
import Link from '../../Routing/Components/Link'

import Config from '../../Utils/Constants/Config'

export interface Static {
    Title: string
    Sites: string
    Site: string
    Email: string
}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {

}

export type Type = React.FC<Props> & Static

const Root = Styled.section`
    text-align: center;
`

const ContactTitle = Styled(Title)`
    font-size: 130%;
    font-variant: small-caps;
    font-weight: normal;
    letter-spacing: 0.2em;
    margin: 0;
    
    ${Large`
        font-size: 130%;
    `}
    
    ${Medium`
        word-spacing: 0;
    `}
    
    ${Small`
        font-size: 130%;
    `}
`

const Sites = Styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1rem auto;
    overflow: hidden;
    width: 13.2rem;
`

const Site = Styled(MultiImage)`
    ${Size('1.75rem')}
    border-radius: 0.3rem;
    overflow: hidden;
    
    &:first-of-type {
        margin-left: 0;
    }
    
    &:last-of-type {
        margin-right: 0;
    }
`

const Email = Styled(Link)`
    font-size: 90%;
    letter-spacing: 0.03rem;
`

const Contact: Type = ({ ...props }) => {

    const sites = React.useMemo(() => (
        Config.sites.map((([name, url, title], i) => (
            <Site pathname={url} name={`Footer/${name}.svg`} key={i}>
                {title}
            </Site>
        )))
    ), [])

    return (
        <Root {...props}>
            <ContactTitle>
                {Config.name}
            </ContactTitle>
            <Sites>
                {sites}
            </Sites>
            <Email pathname={Config.email}>
                {Config.email}
            </Email>
        </Root>
    )

}

Contact.Title = ContactTitle
Contact.Sites = Sites
Contact.Site = Site
Contact.Email = Email

export default Contact