import React from 'react'
import Styled from 'styled-components'

export interface Static {

}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {

}

export type Type = React.FC<Props> & Static

const Draggable = Styled.div`
    cursor: grab;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    scrollbar-width: none;
    user-select: none;
    width: 100%;
    
    &::-webkit-scrollbar {
        display: none;
    }
`

const isTouchable = 'ontouchstart' in window

const DragScroll: Type = ({ ...props }) => {

    const scrollable = React.useRef<HTMLElement>()

    React.useEffect(() => {
        if (scrollable.current) {
            scrollable.current.scrollLeft = scrollable.current.scrollWidth
        }
    }, [scrollable])

    React.useEffect(() => {
        if (scrollable.current && !isTouchable) {
            let startPos = 0
            let startScroll = 0

            scrollable.current.addEventListener('mousedown', event => {
                startPos = event.pageX
                startScroll = scrollable.current!.scrollLeft

                scrollable.current!.onmousemove = event => {
                    scrollable.current!.scrollLeft = startScroll - (event.pageX - startPos)
                }
            })

            scrollable.current.addEventListener('mouseup', () => {
                scrollable.current!.onmousemove = null
            })
        }
    }, [])

    return (
        <Draggable{...props} ref={scrollable as any} />
    )

}

export default DragScroll