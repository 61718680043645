import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'
import { useLocation } from 'react-router-dom'
import useEvent from '@react-hook/event'

import { FontFace, Size } from '../../Style/Constants/Mixin'
import Footer from './Footer'
import Urls, { Query } from '../../Routing/Utils/Urls'
import FixedSlider from '../../Slider/Components/FixedSlider'
import useElement from '../../System/Hooks/useElement'
import Color from '../../Style/Constants/Color'

const GlobalStyles = createGlobalStyle`
     ${FontFace('Montserrat', 'Montserrat.woff2')}
     ${FontFace('Montserrat', 'MontserratThin.woff2', '100')}
     ${FontFace('Montserrat', 'MontserratBold.woff2', 'bold')}

    html, body {
        overflow: hidden;
    }

    body {
        background-color: ${Color.BACKGROUND};
        color: ${Color.LIGHT};
        font-family: Montserrat, Arial;
        margin: 0;
    }
    
    a {
        color: inherit;
        text-decoration: inherit;
    }
    
    button, input, textarea, select {
        background-color: transparent;
        border: inherit;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        outline: inherit;
        -webkit-appearance: none;
    }
    
    input, textarea, select {
        border-bottom: 1px solid ${Color.LIGHT};
        padding: 0.5rem;
    }
    
    a, button {
        color: inherit;
        cursor: pointer;
    }
    
    button {
        border: none;
        cursor: pointer;
        outline: none;
        text-align: center;
    }

    input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    
    select option {
        background-color: ${Color.DARKEST};
        color: ${Color.LIGHTEST};
    }
    
    p, h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
        margin: 0;
    }
    
    
    @media (hover: hover) {
        ::-webkit-scrollbar {
            width: 15px;
        }
        
        ::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0);
            background-color: #444;
        
            &:hover {
                // background-color: #222;
            }
        }
        
        ::-webkit-scrollbar-corner {
            background: rgba(0, 0, 0, 0);
        }
        
        ::-webkit-scrollbar-thumb {
            background-color: #666;
        
            &:hover {
                background-color: #888;
            }
        }
    }
    
    #root {
        backface-visibility: hidden;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        overflow-x: hidden;
        overflow-y: auto;
        overscroll-behavior: none;
        position: relative;
    }
    
    #fixed-image, #fixed-nav, #fixed-video {
        ${Size('100%', '100vh')};
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        
        & > * {
            pointer-events: all;
        }
    }
`

interface Props {

}

const updateVh = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)

const App: React.FC<Props> = ({ children }) => {

    const location = useLocation()
    const { app, fixedVideo, fixedImage } = useElement()

    React.useEffect(() => {
        updateVh()
    }, [])

    useEvent(window, 'resize', updateVh)

    React.useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                Urls.replace({ hash: null })
            }, 500)
        }
    }, [location.hash])

    React.useEffect(() => {
        if (app) app.scrollTo(0, 0)
    }, [location.pathname, app])

    return (
        <>
            <GlobalStyles />
            {children}
            <Footer />
            {ReactDOM.createPortal(<FixedSlider queryName={Query.VIDEO} />, fixedVideo!)}
            {ReactDOM.createPortal(<FixedSlider queryName={Query.IMAGE} />, fixedImage!)}
        </>
    )
}

export default App