import React from 'react'
import Styled, { css } from 'styled-components'

import { LinkData } from '../../Routing/types'
import Color from '../../Style/Constants/Color'
import { Image } from '../../Style/Constants/Mixin'

export interface Static {

}

interface TagData extends LinkData {
    status?: number // TODO: Enum TagStatus instead of number?
}

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
    tags: TagData[]
}

export type Type = React.FC<Props> & Static

interface TagProps {
    status?: number
}

const mapStatusToIcon = [null, 'Top', 'Plus', 'Minus']


const Root = Styled.div`
    margin-left: -0.3rem;
    width: calc(100% + 2 * 0.3rem);
    margin-top: 1rem;
`

const Tag = Styled.div<TagProps>`
    background-color: ${Color.MEDIUM};
    display: inline-block;
    font-size: 90%;
    margin: 0.25rem;
    padding: 0.5rem 0.2rem;
    
    ${({ status }) => status ? css`
        ${Image('Layout/Tag/' + mapStatusToIcon[status] + '.svg', '1rem auto', '0.2rem center')};
        padding-left: 1.4rem;
    ` : null}
`

const TagGroup: Type = ({ tags, ...props }) => {

    const items = React.useMemo(() => (
        tags.map(({ text, ...link }, i) => (
            <Tag {...link} key={i}>
                {text}
            </Tag>
        ))
    ), [tags])

    return (
        <Root {...props}>
            {items}
        </Root>
    )

}

export default TagGroup